<template>
  <div class="cycle-container">

    <div class="cycle-header default-app-bg">
      <p>{{ cycle_desig }}</p>
      <p>Data Inicial: {{ cycle_begin }}</p>
      <p>Data Final: {{ cycle_end }}</p>
    </div>

    <div class="cycle-scales" :class="cycleEnded ? 'is-disabled' : ''">
      <div class="quant">
        <div class="cycle-title div-scale-header">
          <div class="div-icon-container">
            <i class="fas fa-thumbs-down chart-icon" ref="minimumValue"></i>
          </div>
          <div class="scale-title">Escala Qualitativa</div>
        </div>
        <table class="table-scale">
          <tr class="content" v-for="(qual, i) in quali" :key="i">
            <td class="td-minimum">
              <input type="radio" class="radio-option" :value="qual.id ?? i" v-model="minimum" ref="minimum" />
            </td>
            <td class="td-value">
              <input @blur="CheckNumber(qual.quali_min)" type="text" class="first-input"
                :placeholder="qual.quali_min != '' ? qual.quali_min : 'Valor'" v-model="qual.quali_min" />
            </td>
            <td class="td-desig">
              <input type="text" :placeholder="qual.quali_desig != '' ? qual.quali_desig : 'Descrição'
                " v-model="qual.quali_desig" />
            </td>

            <td class="td-color">
              <select class="colors" :class="`${qual.quali_color}-quali-color-bg`" v-model="qual.quali_color"
                :id="`color-${i}`" ref="color">
                <option class="red-quali-color-bg" value="red"></option>
                <option class="orange-quali-color-bg" value="orange"></option>
                <option class="yellow-quali-color-bg" value="yellow"></option>
                <option class="blue-quali-color-bg" value="blue"></option>
                <option class="darkgreen-quali-color-bg" value="darkgreen"></option>
                <option class="lightgreen-quali-color-bg" value="lightgreen"></option>
              </select>
            </td>
          </tr>
        </table>
        <div class="btn">
          <input type="text" class="max-value" value="100" disabled />
          <button class="minus-btn" ref="minus" @click="RemoveQuali">-</button>
          <button class="plus-btn" ref="plus" @click="AddQuali">+</button>
        </div>
      </div>
    </div>

    <div v-if="arrCycleIndicators[0] != undefined && cycle_id != Number" class="scope">
      <SearchIndicator :templateID="'0'" class="search" :cycle="true" :cycleId="cycle_id"
        :class="cycleEnded ? 'is-disabled' : ''" />
      <br />
      <table class="scopeRow sco_color_1">
        <tr>
          <td class="tdIcon">
            <i class="fas fa-industry icon fa-2x" aria-hidden="true"></i>
          </td>
          <td class="tdDesig">
            <div class="divDesig">Empresa</div>
            <div>
              Indicadores: {{ arrCycleIndicators[0]["INDICATORS"].length }}
            </div>
          </td>
        </tr>
      </table>
      <CycleIndicatorList :arrIndicators="arrCycleIndicators[0]['INDICATORS']" class="tblIndicators"
        :cycle_id="cycle_id" :cycleEnded="cycleEnded" />
    </div>
    <div v-if="CompCycles['INDICATORS'] != undefined" class="scope">
      <table class="scopeRow sco_color_4">
        <tr>
          <td class="tdIcon">
            <i class="fas fa-chart-pie icon fa-2x" aria-hidden="true"></i>
          </td>
          <td class="tdDesig">
            <div class="divDesig">Comp</div>
            <div>Ciclos: {{ CompCycles["INDICATORS"].length }}</div>
          </td>
        </tr>
      </table>
      <TemplateCirvalueList :arrIndicators="CompCycles['INDICATORS']" :scopeId="4" :select="true"
        :class="cycleEnded ? 'is-disabled' : ''" />
    </div>

    <div v-if="_360Cycles['INDICATORS'] != undefined" class="scope">
      <table class="scopeRow sco_color_5">
        <tr>
          <td class="tdIcon">
            <i class="fas fa-chart-area icon fa-2x" aria-hidden="true"></i>
          </td>
          <td class="tdDesig">
            <div class="divDesig">360</div>
            <div>Ciclos: {{ _360Cycles["INDICATORS"].length }}</div>
          </td>
        </tr>
      </table>
      <TemplateCirvalueList :arrIndicators="_360Cycles['INDICATORS']" :scopeId="5" :select="true"
        :class="cycleEnded ? 'is-disabled' : ''" />
    </div>
    <div class="div-buttons">
      <div class="div-return">
        <i ref="return" class="fas fa-arrow-circle-left fa-2x" @click="$router.back()"
          @mouseenter="Gems.Tooltip($refs.return, 'Voltar', 'tooltip')"></i>
      </div>

      <button class="btn-action" v-if="!cycleEnded" @click="StoreScales">
        GRAVAR
      </button>
    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";
import CycleIndicatorList from "../components/CycleIndicatorList.vue";
import SearchIndicator from "../components/SearchIndicator";
import TemplateCirvalueList from "../components/TemplateCirvalueList";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "cycle-detail",
  components: {
    CycleIndicatorList,
    SearchIndicator,
    TemplateCirvalueList,
  },
  data() {
    return {
      cycle: Array,
      quali: Array,
      scope: Array,
      deletedQuali: [],
      cycle_id: Number,
      cycle_desig: Number,
      cycle_begin: Number,
      cycle_end: Number,
      cycle_active: Number,
      cycleEnded: Boolean,
      minimum: Number,
      Gems
    };
  },
  methods: {
    CheckNumber(value) {
      if (!(Number.isNaN(value) !== true)) {
        var regra = "/^[0-9]+$/";
        if (value.match(regra)) {
          alert("Numero: " + value)
        } else {
          Gems.Telegram("Permitido somente números para a métrica de intervalos", "tele-not-allowed")
        }
      }
    },

    ...mapActions([
      "ResetMatrix",
      "FetchCycleIndicator",
      "SaveTemplateCycle",
      "SaveCycleMatrix",
      "GetThirdPartyApplicationChoice",
    ]),

    ...mapMutations(['resetStateEntity']),

    StoreScales() {
      for (let qual in this.quali) {
        if (
          this.quali[qual]["quali_desig"] === "" ||
          this.quali[qual]["quali_min"] === ""
        ) {
          Gems.Telegram(
            "A escala qualitativa possui campos vazios",
            "tele-not-allowed"
          );
          return;
        }
      }

      if (!this.cycleEnded) {
        this.SetScales();
        this.SaveTemplateCycle(this.cycle_id);
      }
    },

    async SetScales() {
      let isNumeric;
      for (let i = 0; i < this.quali.length; i++) {
        if (!Number.isNaN(Number(this.quali[i].quali_min))) {
          if (i + 1 == this.quali.length) {
            this.quali[i]["quali_max"] = 100;
          } else {
            this.quali[i]["quali_max"] = parseInt(
              this.quali[i + 1]["quali_min"],
              10
            );
          }
          if (
            this.quali[i]["id"] == this.minimum ||
            (this.quali[i]["id"] == undefined && i == this.minimum)
          ) {
            this.quali[i]["minimum"] = true;
          } else {
            this.quali[i]["minimum"] = false;
          }

          this.quali[i]["quali_min"] = parseInt(this.quali[i]["quali_min"], 10);
        } else {
          isNumeric = false;
        }
      }

      if (isNumeric !== false) {
        const response = await KPI.CallBackEnd("post", "quali-control", {
          quali: this.quali,
          cycleId: this.cycle_id,
          deletedQuali: this.deletedQuali,
          cycle: this.ArrCycle,
        })

        if (response.status === 200) {
          this.deletedQuant = [];
          this.quali = [];
          this.GetQuali(this.cycle_id);
        } else {
          Gems.Telegram("Erro ao gravar!", "tele-not-allowed");
        }
      }
    },

    async GetQuali(cycle) {
      const response = await KPI.CallBackEnd("get", "quali-control", {
        params: { cycle: cycle },
      })

      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          if (Number(response.data[i]["minimum"]))
            this.minimum = response.data[i]["id"]
        }

        this.quali = response.data;
      }
    },

    AddQuali() {
      if (this.quali.length <= 9) {
        this.quali.push({
          quali_minimum: "",
          quali_desig: "",
          quali_min: "",
          quali_color: "",
        });
      } else {
        Gems.Telegram(
          "Impossível Adicionar mais do que 10 escalas!",
          "tele-not-allowed"
        );
      }
    },

    RemoveQuali() {
      this.deletedQuali.push(this.quali.pop());
    },
  },
  computed: {
    ...mapGetters([
      "arrCycleIndicators",
      "CompCycles",
      "_360Cycles",
      "ArrCycle",
    ]),
  },
  created() {
    if (history.state.cycleId == undefined) {
      this.$router.push({ name: "cycle" });
    }
  },
  async mounted() {
    this.cycle_id = history.state.cycleId
    this.cycle_desig = history.state.cycleDesig
    this.cycle_begin = history.state.cycleBegin
    this.cycle_end = history.state.cycleEnd
    this.cycle_active = history.state.cycleActive

    this.cycleEnded = this.cycle_end <= new Date().toJSON().slice(0, 10)

    await this.GetThirdPartyApplicationChoice()
    this.GetQuali(this.cycle_id)
    this.FetchCycleIndicator(this.cycle_id)

    if (this.$refs.minus)
      Gems.Tooltip(this.$refs.minus, "Remover última linha da escala", "tooltip")

    if (this.$refs.plus)
      Gems.Tooltip(this.$refs.plus, "Adicionar linha a escala", "tooltip")
  },
  updated() {
    Gems.Tooltip(this.$refs.minimumValue, "Necessidade de Melhoria", "tooltip");
    if (this.$refs.minimum) {
      for (let elem in this.$refs.minimum) {
        Gems.Tooltip(this.$refs.minimum[elem], 'Valor até o qual se considera uma "Necessidade de melhoria" (excluso)', "tooltip")
      }
    }

    if (this.$refs.color) {
      for (let elem in this.$refs.color) {
        Gems.Tooltip(this.$refs.color[elem], "Define a cor para as avaliações correspondentes", "tooltip")
      }
    }
  },
  beforeRouteLeave() {
    this.ResetMatrix();
    this.resetStateEntity();
  },
};
</script>

<style scoped>
@import '../assets/matrix.css';

.cycle-container {
  margin: -10px 0;
  padding: 0px 16px 0px 16px;
}

.cycle-header {
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.cycle-header p {
  margin: 2px;
}

.cycle-header p:first-child {
  font-size: 1.25em;
  font-weight: bold;
}

.cycle-scales {
  display: flex;
  margin-bottom: 40px;
}

.radio-option {
  transform: scale(1.5);
  height: -webkit-fill-available;
  margin: 0;
}

.quant {
  flex: .5;
}

.definitions .content {
  background-color: var(--light-gray-color);
  padding: 2%;
  height: 100%;
}

.quant .content {
  margin-bottom: 10px;
  display: flex;
}

.cycle-title {
  text-align: center;
  height: 30px;
  font-size: 1.125em;
  padding-top: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  border-radius: 4px;
}

.definitions .cycle-title {
  margin-bottom: 10px;
}

.quant .content input {
  border: 1px solid var(--medium-gray-color);
  border-radius: 4px;
  font-size: 1em;
}

.first-input {
  text-align: center;
  width: 50px;
}

.btn {
  display: flex;
  padding-right: 18px;
}

.btn button {
  height: 42px;
  width: 39%;
}

.minus-btn {
  margin-right: 5px;
}

.minus-btn,
.plus-btn {
  font-size: 2em !important;
}

.max-value {
  margin-left: 52px;
  margin-right: 5px;
  width: 45px;
  height: 30px;
  text-align: center;
}

.div-buttons {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}

.div-buttons button {
  width: 300px;
  font-size: 1.25em;
}

.scopeRow {
  width: 100%;
  border-spacing: 0px;
  color: var(--light-gray-color);
}

.tdIcon {
  width: 5%;
  text-align: center;
}

.tdDesig {
  width: 20%;
  padding: 5px;
}

.divDesig {
  font-size: large;
  font-weight: bold;
  color: var(--light-gray-color);
}

.tdWeight {
  text-align: left;
}

.divWeight {
  width: 10%;
  text-align: center;
}

.txtWeight {
  width: 30px;
  text-align: center;
  border-radius: 2px;
  border: none;
  margin: 3px;
}

.is-disabled {
  pointer-events: none;
}

.colors {
  height: 42px;
}

.definitions {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}

.chk {
  transform: scale(1.5);
}

.chk-label {
  margin-left: 10px;
  font-weight: bold;
}

.div-scale-header {
  display: grid;
  grid-template-columns: 10% 90%;
}

.chart-icon {
  font-size: 1.6em;
  width: 73%;
  pointer-events: all;
}

.chart-icon,
.scale-title {
  align-self: center;
}

.table-scale {
  width: 100%;
  padding-right: 15px;
}

.table-scale tr td {
  height: 42px;
}

.td-value input,
.td-desig input,
.td-color select {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.td-minimum {
  width: 10%;
}

.td-value {
  width: 12%;
}

.td-desig {
  width: 68%;
}

.td-color {
  width: 10%;
}

.td-minimum {
  align-self: center;
  text-align: center;
}

.td-desig,
.td-color {
  margin-left: 3px;
}

.div-icon-container {
  align-self: end;
}

.div-return {
  display: flex;
  align-items: center;
}

.div-return i {
  cursor: pointer;
}

.div-return i:hover {
  color: var(--default-app-color);
}
</style>
