const state = {
  switchValue: false,
  id: Number,
};

const getters = {
  switchValue: (state) => {
    return state.switchValue;
  },
  idStore: (state) => {
    return state.id;
  },
};

const actions = {
  StoreSwitch({ commit }, switchValue) {
    commit("storeCycle", switchValue);
  },

  StoreId({ commit }, id) {
    commit("storeId", id);
  },
};

const mutations = {
  storeSwitch: (state, switchValue) => (state.switchValue = switchValue),
  storeId: (state, id) => (state.id = id),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
