<template>
  <div>
    <div :class="GetClass()" v-show="userInfoTable !== true">
      <input
        type="text"
        ref="txtName"
        @keyup="SearchUsers()"
        placeholder="Pesquisar colaborador..."
      />
    </div>
    <div v-if="draggable">
      <div class="inner-container">
        <div
          v-for="(user, index) in toggle ? arrUsers : arrUsersActive"
          :key="index"
          class="draggable card cursor-pointer"
          :draggable="true"
          @dragstart="StartDrag($event, user)"
        >
          <UserBlock :objUser="user" :cardUser="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserBlock from "./UserBlock.vue";
import { KPI } from "../kpi";

export default {
  name: "UsersFilter",
  props: {
    draggable: Boolean,
    updateTeamsUser: Boolean,
    userInfoTable: Boolean,
    toggle: Boolean,
  },
  components: {
    UserBlock,
  },
  data() {
    return {
      arrUsers: [],
      arrUsersActive: [],
      openUser: [],
      abortController: null,
    };
  },
  methods: {
    StartDrag: (event, user) => {
      event.dataTransfer.setData("user", JSON.stringify(user));
    },

    async SearchUsers() {
      if (this.abortController != null) this.abortController.abort();

      this.abortController = new AbortController();
      const response = await KPI.CallBackEnd("get", "filter-users", {
        params: {
          search_text: this.$refs.txtName.value,
          cycle: localStorage.getItem("cycle"),
          signal: this.abortController.signal,
        },
      })
      
      this.arrUsersActive = [];
      this.arrUsers = response.data[0];
      for (let user in response.data[0]) {
        if (response.data[0][user]["active"] == 1) {
          this.arrUsersActive.push(response.data[0][user]);
        }
      }
    },

    GetClass() {
      if (this.userInfoTable === true) {
        return "search search-table";
      } else {
        return "search";
      }
    },
  },
  mounted() {
    this.SearchUsers();
  },
  watch: {
    updateTeamsUser() {
      this.SearchUsers();
    },
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search {
  margin: 10px 0 10px 10px;
}

.search input {
  width: 96%;
  height: 20px;
}

/* table-user */
.search-table {
  position: relative;
  top: -40px;
  left: 270px;
}

.draggable {
  cursor: move;
}

.inner-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0px;
  grid-column-gap: 5px;
  margin: 22px 10px 10px 10px;

  /* margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly; */
}

.table-user {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1400px) {
  /* input {
    width: 300px !important;
  } */
  .inner-container {
    grid-row-gap: 5px;
    grid-column-gap: 5px;
  }

  .card {
    margin: 0 1px 10px 1px;
    width: 131px;
  }
}
</style>
