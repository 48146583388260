<template>
  <div class="container">
    <div class="policy">
      <div class="container-padding">
        <div class="divLogo"></div>
        <div class="title">INFORMAÇÃO SOBRE O SISTEMA</div>
        <p class="first-paragraph">
          Os sistemas CIRVALUE são sistemas de avaliação contínua do desempenho, 
          baseados em indicadores, objetivos e resultados, e também competências 
          e comportamentos profissionais, que potenciam o feedback interpessoal, 
          a análise colaborativa e a gestão contínua do desempenho.
        </p>
        <p>
          A sua Organização/Empresa adotou este Sistema numa ou várias vertentes, 
          com o fim de orientar, acompanhar e avaliar o desempenho e o comportamento 
          profissional dos seus colaboradores e, simultaneamente, potenciar um "feedback" 
          claro e frequente, que sustente e encoraje a melhoria contínua do desempenho e o 
          desenvolvimento pessoal e profissional de cada pessoa.
        </p>
        <p>
          Se conseguiu aceder, isso significa que a sua Organização/Empresa o/a registou 
          como utilizador/a e que está ativo/a no sistema CIRVALUE.
        </p>
        <p>
          A utilização de sistemas de Gestão e Avaliação do Desempenho nas
          Organizações é legítima no âmbito das relações contratuais de trabalho
          subordinado e não carece de consentimento pessoal, exceto para a
          eventual utilização de categorias especiais de dados, como é o caso da
          sua imagem.
        </p>
        <p>
          A utilização de sistemas de Gestão e Avaliação do Desempenho nas Organizações/Empresas é 
          legítima no âmbito das relações contratuais de trabalho subordinado e não carece de consentimento pessoal 
          relativamente aos dados necessários à ponderação do desempenho individual e de equipas. 
          Quando estejam em causa outras finalidades específicas, ainda que conexas, designadamente, 
          facilitar a imediata identificação da pessoa através da utilização de uma fotografia de rosto, 
          para as quais não haja outro fundamento de licitude, os dados pessoais só serão utilizados mediante 
          autorização expressa do respetivo titular dos dados.
        </p>
        <p>
          Considerando a relevância da informação contida em qualquer sistema de Gestão e Avaliação do Desempenho, 
          a informação, incluindo os registos de avaliação nos diversos ciclos avaliativos e comentários produzidos, 
          será guardada e conservada na base de dados enquanto estiver ativo/a no sistema, e durante um período de 13 meses, 
          depois de, eventualmente, permanecer desativado/a ininterruptamente. Depois desse período, 
          toda a informação que lhe diga respeito será automaticamente apagada neste sistema.
        </p>
        <p>
          Todos os dados e informação contida na base de dados, estão encriptados, bem como as comunicações associadas.
        </p>
        <p>
          Por outro lado, o sistema proporciona-lhe um acesso personalizado, protegido por uma password definida por si, que pode alterar em qualquer momento.
        </p>
        <b>Informação sobre algoritmos nos sistemas CIRVALUE</b>
  
        <p>“Algoritmos são sequências finitas de ações executáveis que visam obter uma solução para um determinado tipo de problema”.</p>
  
        <p>
          No âmbito dos sistemas CIRVALUE de Avaliação de Desempenho, os algoritmos estão presentes através de cálculos que o sistema 
          executa para apurar a avaliação de um/a utilizador/a num dado momento. Importa referir que esses cálculos são efetuados com base em fórmulas, 
          pesos e escalas selecionáveis e definidas na parametrização feita por cada Organização/Empresa cliente, através da pessoa a 
          quem a Organização/Empresa atribuir essa responsabilidade (administrador/a do sistema).
        </p>
  
        <p>
          Esses pesos e escalas ficam depois visíveis para todos os/as utilizadores/as, diretamente ou através de etiquetas dinâmicas, e os cálculos, 
          quando nenhuma outra informação for facultada, são sempre médias simples ou ponderadas pelos pesos indicados.
        </p>
  
        <p>
          Existem, ainda, modos diversos de utilização ou funcionamento do sistema, também escolhidos pela pessoa administrador/a do sistema, 
          que implicam formas diferentes de selecionar ou contabilizar os dados, mas esses modos escolhidos estão claramente identificados na interface dos/as utilizadores/as, 
          estando também disponível uma explicação sobre como operam, diretamente acessível ou constante nas “Ajudas” disponibilizadas aos/às utilizadores/as.
        </p>
  
        <p>
          Os sistemas CIRVALUE são disponibilizados à sua Organização/Empresa de forma a que sejam geridos sem intervenção de pessoas externas, 
          nomeadamente trabalhadores do fabricante ou de entidades terceiras que possam comercializar os sistemas.
        </p>
  
        <p>
          Sugerimos, assim, que contacte o/a Administrador/a CIRVALUE ou a área de Proteção de Dados da sua Organização/Empresa, 
          para obter mais informação sobre a parametrização internamente definida, designadamente, as permissões definidas, 
          as pessoas que podem ter acesso a informação que lhe diga respeito e outros aspetos relacionados com privacidade ou 
          proteção dos dados pessoais tratados pela sua Organização/Empresa.
        </p>
        <p>
          Obtenha informação de apoio sobre como utilizar o CIRVALUE, acedendo, às “Ajudas” disponibilizadas nos menus.
        </p>
  
        <p>Consulte a Política de Privacidade e Proteção de Dados da InPeople (Fabricante)</p>
  
        <br>

        <p>Para prosseguir a sua experiência no CIRVALUE, preencha os campos abaixo</p>
      </div>

      <div class="div-inputs container-padding">
        <form class="check">
          
          <br />
          <br />
          
          <div>
            <div class="div-label">
              <b>Tomei conhecimento e entendi a informação prestada</b> (obrigatório
              para prosseguir a experiência no CIRVALUE)
              <input type="checkbox" id="accept" v-model="accept" />
            </div>
          </div>

          <br />
          <br />

          <div>
            <div class="div-label">
              <b>Autorizo a utilização da minha fotografia neste ciclo avaliativo,
              como forma de facilitar a minha identificação inequívoca pelas
              demais pessoas utilizadoras (facultativo; poderá retirar esta
              autorização a todo o tempo, e a sua imagem deixará de aparecer)</b>
              <div class="div-radios-inputs">
                <input type="radio" id="photo" @click="photo = true; firstAccess = false" :checked="photo && !firstAccess"/>
                <label for="">Sim</label>
                <input type="radio" id="photo" @click="photo = false; firstAccess = false" :checked="!photo && !firstAccess"/>
                <label for="">Não</label>
              </div>
            </div>
          </div>
        </form>

        <br />
        <br />

        <div class="check-warning">
          <p>
            Se ativou a opção “Sim”, o seu consentimento fica registado e, da próxima vez que entrar no sistema, durante o mesmo ciclo avaliativo, esta opção estará inativa. 
          </p>
          <p>
            Se quiser retirar o consentimento anteriormente prestado, basta ativar a opção “Não” quando voltar a entrar no sistema (no mesmo ciclo avaliativo) 
            e a sua fotografia deixará de aparecer. 
          </p>
        </div>
        
        <button class="btn-check btn-action" :class="{ disabled: !accept }" @click="Accept()">
          PROSSEGUIR EXPERIÊNCIA NO CIRVALUE
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "privacy",
  data() {
    return {
      userId: Number,
      photo: Number,
      accept: Boolean,
      firstAccess: Boolean
    };
  },
  methods: {
    async Accept() {
      if (this.accept !== true) {
        Gems.Telegram("Obrigatório dar ciência da declaração de privacidade", "tele-not-allowed")
      } else {
        if (this.photo) 
          this.photo = 1
        else
          this.photo = 0

        await KPI.CallBackEnd("post", "policy-control", {
          photo: this.photo,
          user_id: this.userId,
          cycle_id: localStorage.getItem("cycle"),
        })

        this.$router.push({ name: "profile" });
      }
    },

    async GetPolicy() {
      const response = await KPI.CallBackEnd("get", "policy-control")

      this.firstAccess = response.data.first_access
      this.accept = response.data.access
      this.photo = response.data.photo
      this.userId = response.data.user_id
    },
  },
  mounted() {
    this.GetPolicy();
  },
};
</script>

<style scoped>
.container {
  margin: 0 auto;
  width: 60%;
  background-color: white;
  overflow: auto;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
}


.divLogo {
  padding: 15px;
  height: 150px;
  background-image: url("../images/logo_cirvalue.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.title {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 45px;
  text-align: center;
}

.first-paragraph {
  margin-top: 0px;
}

.policy {
  margin: 0 auto;
  text-align: justify;
  font-size: 1.4em;
}

.check {
  display: flex;
  flex-direction: column;
}

.check div {
  display: flex;
}

.div-label {
  display: block !important;
}

.check input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: none;
  position: relative;
  display: inline-block;
  vertical-align: sub;
  cursor: pointer;
  flex: 1;
  align-self: center;
}

.check label {
  display: inline-block;
  vertical-align: middle;
}

.div-radios-inputs {
  align-items: center;
  display: inline-flex !important;
}

.div-radios-inputs input {
  margin: 0 5px;
  cursor: pointer;
}

.btn-check {
  border: none;
  text-transform: uppercase;
  color: white;
  font-size: 0.7em;
  height: 50px;
  margin-bottom: 60px;
}

.btn-check:hover {
  height: 50px;
}

.btn-action:hover {
  opacity: 0.8;
  background-color: var(--default-app-color);
  color: white;
}

.check-warning {
  margin-bottom: 40px;
}

.disabled {
  pointer-events: none !important;
  opacity: .4 !important;
}

.div-inputs {
  background-color: var(--light-gray-color);
}

.container-padding {
  padding: 0px 70px 0px 70px;
}
</style>
