<template>
  <div class="limit-size">
    <div class="container">
      <div class="cycle-header-btn">
        <div ref="cycleDetail">
          <i
            class="fas fa-cog fa-2x cursor-pointer"
            aria-hidden="true"
            @click="CycleDetail"
            v-if="admin === true"
          ></i>
        </div>
        <div ref="logout">
          <i
            class="fas fa-sign-out-alt fa-2x cursor-pointer"
            aria-hidden="true"
            @click="$router.push({ path: '/logout' })"
          ></i>
        </div>
      </div>
      <div class="cycle-header">
        <div class="divLogo"></div>
        <h1>Os seus ciclos de avaliação</h1>
      </div>
      <table class="table">
        <thead class="default-app-bg">
          <tr>
            <th>Ciclo</th>
            <th>Data Início</th>
            <th>Data Fim</th>
          </tr>
        </thead>
        <tbody v-for="cycle in cycles" :key="cycle.cycle_id">
          <tr @click="SetCycle(cycle)" :class="SetClass(cycle)" class="cursor-pointer">
            <td>{{ cycle.cycle_desig }}</td>
            <td>{{ cycle.cycle_begin }}</td>
            <td>{{ cycle.cycle_end }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "cycles",
  data() {
    return {
      cycles: [],
      admin: Boolean,
    };
  },
  methods: {
    async SetCycle(cycle) {
      const response = await KPI.CallBackEnd("get", "policy-control")

      localStorage.setItem("cycle", cycle.cycle_id)
      if (response.data.access == true)
        this.$router.push({ name: "matrix" })
      else 
        this.$router.push({ name: "privacy", params: { first: true, cycle_id: cycle.cycle_id }, })
    },

    CycleDetail() {
      this.$router.push({ name: "cycle" });
    },

    SetClass(cycle) {
      if (cycle.cycle_active == true) {
        return "tr-active";
      } else {
        return "tr-inactive";
      }
    },
  },
  async mounted() {
    if (history.state?.change)
      await Gems.Telegram(history.state.message, "tele-success");

    Gems.Tooltip(this.$refs.cycleDetail, "Definições", "tooltip");
    Gems.Tooltip(this.$refs.logout, "Logout", "tooltip");

    const response = await KPI.CallBackEnd("get", "get-cycles")

    const innerCycles = response.data[0];
    this.admin = response.data[1];
    for (let cycle in innerCycles) {
      this.cycles.push(innerCycles[cycle]);
    }
  },
};
</script>

<style scoped>
.limit-size {
  margin: 0 auto;
  width: 1200px !important;
}

.container {
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 40%);
  width: 70%;
  font-size: 10pt;
  overflow: auto;
  min-height: 100vh;
}

.cycle-header {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 40px 20px;
}
.cycle-header div {
  display: flex;
}

.cycle-header h1 {
  text-align: center;
  font-weight: 100;
  font-size: 32px;
  margin: 0px;
}

.cycle-header-btn {
  margin: 12px 12px 4px 12px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  transform: scaleX(-1);
  display: flex;
  justify-content: space-between;
}

.table {
  width: 100%;
  text-align: center;
  border: 0px solid;
  border-collapse: collapse;
  font-size: 14px;
}

.table td,
table.table th {
  border: 0px solid;
}

.tr-active {
  color: #000000;
}

.tr-active:hover {
  background-color: var(--special-app-color);
}

.tr-inactive {
  color: var(--default-gray-color);
  background-color: var(--light-gray-color);
}

.tr-active:hover,
.tr-inactive:hover {
  color: var(--second-app-color);
}

.table thead th {
  color: #ffffff;
  text-align: center;
  width: 20%;
  padding: 9px 3px 10px 3px;
  font-weight: normal;
}

.table tbody tr {
  border-bottom: solid 1px var(--light-gray-color);
}

.table tbody td {
  width: 20%;
  padding: 9px 3px 10px 3px;
}

.table thead th:first-child {
  width: 60% !important;
}

.divLogo {
  padding: 0px;
  height: 150px;
  background-image: url("../images/logo_cirvalue.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
