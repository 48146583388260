<template>
  <div class="scope">
    <table class="scopeRow" :class="`sco_color_${objScope.sco_id}`">
      <tr>
        <td class="tdIcon">
          <div v-for="(icon, index) in iconNames" :key="index">
            <div v-if="icon.id == objScope.sco_id">
              <i :class="icon.name" class="icon fa-2x" aria-hidden="true"></i>
            </div>
          </div>
        </td>
        <td class="tdDesig">
          <div class="divDesig">
            {{ objScope.sco_desig }}
          </div>

          <div v-if="objScope.sco_id != 4 && objScope.sco_id != 5">Indicadores: {{ objScope["INDICATORS"].length }}</div>
          <div v-else>Ciclos: {{ objScope?.INDICATORS?.length }}</div>

          <div>Peso: {{ objScope.sco_weight ? Gems.FormatDecimals(objScope.sco_weight, 2) : '' }}</div>
        </td>
        <td class="tdWeight">
          <div class="divWeight">
            <div>Peso</div>
            <input
              ref="txtWeight"
              type="text"
              class="txtWeight"
              :value="objScope.sco_weight"
              @change="Update()"
            />
          </div>
        </td>
      </tr>
    </table>
    <TemplateIndicatorList
      :arrIndicators="objScope['INDICATORS']"
      :scopeId="objScope.sco_id"
      :company="objScope.sco_id == 1"
      :templateId="templateId"
      v-if="objScope.sco_id != 4 && objScope.sco_id != 5"
    />
    <TemplateCirvalueList
      :arrIndicators="objScope['INDICATORS']"
      :scopeId="parseInt(objScope.sco_id)"
      :select="false"
      :template="true"
      v-else
    />
  </div>
</template>

<script>
import TemplateIndicatorList from "./TemplateIndicatorList";
import TemplateCirvalueList from "./TemplateCirvalueList";
import { mapMutations } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "TemplateScopeBlock",
  props: {
    objScope: {},
    templateId: [String, Number],
  },
  data() {
    return {
      iconNames: {
        0: { id: 1, name: "fas fa-industry" },
        1: { id: 2, name: "fas fa-users" },
        2: { id: 3, name: "fas fa-male" },
        3: { id: 4, name: "fas fa-chart-pie" },
        4: { id: 5, name: "fas fa-chart-area" },
      },
      Gems,
    };
  },
  components: {
    TemplateIndicatorList,
    TemplateCirvalueList,
  },
  methods: {
    ...mapMutations(["updateScope"]),

    async Update() {
      this.$refs.txtWeight.value = await KPI.FormatWeight(
        this.$refs.txtWeight.value
      );

      this.updateScope({scope: this.objScope, weight: this.$refs.txtWeight.value});
    },
  },
};
</script>

<style scoped>
.scopeRow {
  width: 100%;
  border-spacing: 0px;
  color: var(--light-gray-color);
}

.tdIcon {
  width: 5%;
  text-align: center;
}

.tdDesig {
  width: 20%;
  padding: 5px;
}

.divDesig {
  font-size: large;
  font-weight: bold;
  color: var(--light-gray-color);
}

.tdWeight {
  text-align: left;
}

.divWeight {
  width: 10%;
  text-align: center;
}

.txtWeight {
  width: 45px;
  text-align: center;
  margin: 3px;
  border: none;
  border-radius: 5px;
}

.scope {
  margin-bottom: 1px;
}
</style>
