<template>
  <div>
    <TemplateScopeList :arrScopes="arrScopes" :templateId="templateId" />
  </div>
</template>

<script>
import TemplateScopeList from "../components/TemplateScopeList";

export default {
  name: "Home",
  props: {
    arrScopes: [Array, Object],
    templateId: [String, Number],
  },
  components: {
    TemplateScopeList,
  },
};
</script>

<style></style>
