<template>
  <div class="container-table">
    <div class="information-check">
      <div class="information-checkbox red"></div>
      <p class="information-text">
        Em nenhuma circunstância modifique a coluna 'Key' [coluna A]
      </p>
    </div>

    <div class="group">
      <div id="1" class="warning">
        <div class="icon-container">
          <i class="fas fa-info-circle icon cursor-pointer" @click="
            ShowWarning(`<br/>
                          <li>Novo registo:</li>
                          <ul>
                            <li>[coluna A] precisa de estar vazia.</li>
                            <li>O valor da [coluna B] não existe na Base de Dados.</li>
                          </ul>
                          <li>Alteração [Exceto coluna A]:</li>
                          <ul>
                            <li>[coluna A] vazia e valor da [coluna B] existe na Base de Dados (Altera C, D, E e F).</li>
                            <li>[coluna A] não vazia (Altera B, C, D, E e F).</li>
                          </ul>
                          <br>`,
              1
            )
            "></i>
          <span class="txt-rules">Regras para importação</span>
        </div>
      </div>

      <button @click="Export(1)" class="button btn-action big">
        <i class="fas fa-arrow-down"></i>
        EXPORTAR INDICADORES
      </button>
      <table class="ex-table indicators-table">
        <thead>
          <tr>
            <th class="red">A. Key</th>
            <th>B. Código do indicador</th>
            <th>C. Descritor</th>
            <th>D. Descrição</th>
            <th>E. Código da métrica</th>
            <th>F. Unidade</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: A001</td>
            <td>Ex.: Indicador X</td>
            <td>Ex.: Este é um indicador</td>
            <td>Ex.: 2</td>
            <td>Ex.: %</td>
          </tr>
          <tr>
            <td>{{ numericField }}</td>
            <td>{{ alphaNumericField }}</td>
            <td>{{ alphaNumericField }}</td>
            <td>{{ alphaNumericField }}</td>
            <td>{{ alphaNumericField }}</td>
            <td>{{ alphaNumericField }}</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file big">
        <ImportUploadFile :tableName="'indicators'" :inputData="indicators" @select-file="SelectFile($event)"
          @import="Import($event)" />
      </div>
    </div>

    <div class="group">
      <div id="2" class="warning">
        <div class="icon-container">
          <i class="fas fa-info-circle icon cursor-pointer" @click="
            ShowWarning(
              `<li>A tabela inteira será substituída. Todos os registos que não existirem no ficheiro carregado serão apagados da base de dados.</li>`,
              2
            )
            "></i>
          <span class="txt-rules">Regras para importação</span>
        </div>
      </div>

      <button @click="Export(9)" class="button btn-action">
        <i class="fas fa-arrow-down"></i>
        EXPORTAR INFORMAÇÕES E IMPACTOS
      </button>
      <table class="ex-table impacts-table">
        <thead>
          <tr>
            <th>A. N° interno</th>
            <th>B. Comentário</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: A001</td>
            <td>Ex.: Aumento na taxa de receita da empresa</td>
          </tr>
          <tr>
            <td>{{ alphaNumericField }}</td>
            <td>{{ alphaNumericField }}</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :tableName="'impacts'" :inputData="impacts" @select-file="SelectFile($event)"
          @import="Import($event)" />
      </div>
    </div>

    <div class="group">
      <button @click="Export(7, 'metrics')" class="button btn-action">
        <i class="fas fa-arrow-down"></i>
        EXPORTAR MÉTRICAS
      </button>
      <table class="ex-table metrics-table">
        <thead>
          <tr>
            <th>A. Código da métrica</th>
            <th>B. Descritor</th>
            <th class="metric-description">C. Descrição</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: Linear Direta (FA)</td>
            <td>
              Nas métricas diretas, "FA" significa "Fasquia"...
            </td>
          </tr>
          <tr>
            <td>{{ numericField }}</td>
            <td>{{ alphaNumericField }}</td>
            <td>{{ alphaNumericField }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";
import ImportUploadFile from "../components/ImportUploadFile.vue";

export default {
  name: "import",
  data() {
    return {
      file: null,
      indicators: null,
      impacts: null,
      results: null,
      fileUser: "",
      cycle: localStorage.getItem("cycle"),
      genders: null,
      numericField: '(campo numérico)',
      alphaNumericField: '(campo alfa-numérico)',
      booleanField: '(campo binário - 0 ou 1)',
    };
  },
  components: {
    ImportUploadFile
  },
  methods: {
    SelectFile(type) {
      this[type] = document.getElementById(type).files[0]
    },

    async Import(type) {
      const fileInput = document.getElementById(type)
      const file = document.getElementById(type).files[0]

      if (!file) {
        fileInput.click()
        return
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("cycle", this.cycle);
      formData.append("type", type);

      const response = await KPI.CallBackEnd("post", "import", formData);
      if (response.data.error == false) {
        Gems.Telegram(response.data.message, "tele-success");
      } else {
        Gems.Telegram(response.data.message, "tele-not-allowed");
        if (response.data.excel) {
          Gems.ExportExcel(response.data.data);
        }
      }

      document.getElementById(type).value = null;
      this[`${type}`] = null;
    },

    async Export(type, table = null) {
      const response = await KPI.CallBackEnd("post", "export", {
        cycle: this.cycle,
        type,
        table,
      })

      if (response.status == 200)
        Gems.ExportExcel(response.data)
    },

    ShowWarning(text, id) {
      const divWarning = document.getElementById(id);

      if (divWarning.children.length > 1) {
        this.CloseWarning(id);
      } else {
        const div = document.createElement("div");
        divWarning.appendChild(div);

        div.innerHTML += `<button class='fas fa-times close-import' onclick='document.getElementById(${id}).removeChild(document.getElementById(${id}).lastElementChild)'></button>`;
        div.innerHTML += '<li>Apenas importe ficheiros que foram exportados deste interface.</li>'
        div.innerHTML += '<li>Não apague colunas do EXCEL exportado.</li>'
        div.innerHTML += '<li>Os registos serão processados pela ordem em que aparecem no ficheiro.</li>'
        div.innerHTML += '<li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li>'
        div.innerHTML += text;
        div.innerHTML += '<li>Grave o ficheiro com o seguinte formato:</li>'
        div.innerHTML += "<div class='excel-img'>";
        div.setAttribute("class", "div-import-help");
      }
    },

    CloseWarning(id) {
      document
        .getElementById(id)
        .removeChild(document.getElementById(id).lastElementChild);
    },
  },
  mounted() {
    for (let ref in this.$refs) {
      Gems.Tooltip(this.$refs[ref], "Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]", "tooltip");
    }
  },
};
</script>

<style>
.blink-import {
  animation: blink-import 1s step-start infinite;
}

@keyframes blink-import {
  50% {
    background-color: var(--default-app-color);
    color: white;
  }

  100% {
    background-color: inherit;
    color: var(--default-app-color);
    border: 2px solid var(--default-app-color);
  }
}

.excel-img {
  display: block;
  height: 380px;
  width: 612px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/excel.png");
}

.export {
  height: 20px !important;
}
</style>

<style scoped>
.container-table {
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.information-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.information-checkbox {
  width: 22px;
  height: 22px;
  border: solid 1px grey;
  border-radius: 4px;
}

.information-text {
  font-size: 9pt;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}

.upload-file {
  width: 70%;
  display: flex;
  margin-bottom: 5px;
  margin-top: 2px;
  justify-content: space-between;
}

.big {
  width: 100% !important;
}

table.ex-table {
  background-color: #ffffff;
  width: 100%;
  height: 100px;
  text-align: center;
  border-collapse: collapse;
  word-wrap: break-word;
}

.impacts-table,
.teams-table,
.metrics-table {
  width: 70% !important;
}

table.ex-table td,
table.ex-table th {
  border: 1px solid #808080;
  padding: 3px 2px;
}

table.ex-table td {
  word-break: break-word;
}

table.ex-table tbody td {
  font-size: 10px;
  color: #000000;
}

table.ex-table thead {
  background: var(--light-gray-color);
}

table.ex-table thead th {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border-left: 1px solid #808080;
}

table.ex-table {
  font-size: 14px;
}

.metrics-table {
  margin-bottom: 5px;
}

.button {
  width: 70%;
  margin-bottom: 2px;
  margin-top: 10px;
  height: 40px;
  font-size: 13px;
}

.group {
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--medium-gray-color);
}

.warning {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-container {
  display: flex;
  align-items: center;
}

.txt-rules {
  font-weight: 700;
  height: 18px;
  margin-left: 5px;
}

.icon {
  font-size: 1.2rem;
}

.red {
  background-color: #FF4500;
}

.metric-description {
  width: 60%;
}
</style>
