<template>
  <tr class="indicator-row" :class="{'without-result-row' : !KPI.isNumeric(objIndicator.ind_vol)}" ref="indicator">
    <td :class="{'without-result-line' : !KPI.isNumeric(objIndicator.ind_vol)}"></td>

    <td class="td-id">{{ objIndicator.ind_internal }}</td>
    
    <td class="td-desig" ref="designation">
      <span class="line-clamp"> {{ objIndicator.ind_desig }} </span>
    </td>

    <td class="td-weight">{{ Gems.FormatDecimals(objIndicator.ind_weight, 2) }}</td>
    
    <td class="td-metric" ref="metric_description">{{ objIndicator.ind_met }}</td>

    <td class="td-unit">{{ objIndicator.ind_unit }}</td>

    <td class="td-goal" v-if="objIndicator.ind_met_id < 7">{{ Gems.FormatDecimals(objIndicator.ind_goal, 2) }}</td>
    <td class="td-goal" v-else></td>
    
    <td class="td-unit" v-if="objIndicator.ind_met_id < 5">{{ Gems.FormatDecimals(objIndicator.ind_mark, 2) }}</td>
    <td class="td-unit" v-else></td>

    <td class="td-result">
      <div v-if="objIndicator.ind_met_id == 7">
        <div v-if="objIndicator?.METRICS?.length > 0">
          <div v-if="objIndicator.direct_matching == 0" ref="txtVolMetric0">
            <input type="text" class="txt-result" :value="Gems.FormatDecimals(objIndicator.ind_vol, 2)" disabled/>
          </div>
          <Dropdown v-else :text="objIndicator.title ?? objIndicator.METRICS.length" :interval="true" :isOpen="isOpen" @toggle="Toggle()" :tooltip="objIndicator.tooltip">
            <DropdownItem v-for="(row, index) in objIndicator.METRICS" :key="index" :interval="true" :tooltip="row.tooltip">
              {{ row.option_value }}
            </DropdownItem>
          </Dropdown>
        </div>

        <div v-else ref="disabled">
          <input type="text" class="txt-result" disabled />
        </div>
      </div>

      <div v-else-if="objIndicator.ind_met_id == 5 || objIndicator.ind_met_id == 6">
        <IndicatorBooleanVolume :objIndicator="objIndicator" :disabled="true" />
      </div>
      <input type="text" v-else class="txt-result" :value="KPI.isNumeric(objIndicator.ind_vol) ? Gems.FormatDecimals(objIndicator.ind_vol, 2) : ''" disabled />
    </td>

    <td class="td-realization" ref="reali">{{ objIndicator.ind_realization || KPI.isNumeric(objIndicator.ind_vol) ? Gems.FormatDecimals(objIndicator.ind_realization, 2) + '%' : '--' }}</td>

    <td class="td-realization" ref="eval">{{ objIndicator.ind_evaluation || KPI.isNumeric(objIndicator.ind_vol) ? Gems.FormatDecimals(objIndicator.ind_evaluation, 2) + '%' : '--' }}</td>

    <td class="td-above100">
      <select class="ddl-above100" ref="above100" disabled v-if="objIndicator.ind_met_id != 5 && objIndicator.ind_met_id != 6">
        <option :selected="objIndicator.above100 == KPI.ABOVE100.NONE" :value="KPI.ABOVE100.NONE">N/A</option>
        <option :selected="objIndicator.above100 == KPI.ABOVE100.FA" :value="KPI.ABOVE100.FA">FA</option>
        <option :selected="objIndicator.above100 == KPI.ABOVE100.FO" :value="KPI.ABOVE100.FO">FO</option>
      </select>
    </td>

    <td class="td-gauge cursor-pointer" :class="`${objIndicator.status_messages}`" @click="Show('chat')" ref="commentIcon">
      <i class="fas fa-comments" aria-hidden="true"></i>
    </td>

    <td class="td-gauge cursor-pointer" :class="`${objIndicator.quali_color}-quali-color`" @click="Show('gauge')" ref="gaugeIcon">
      <i class="fas fa-tachometer-alt" aria-hidden="true"></i>
    </td>
  </tr>

  <MatrixTrRow
    :objIndicator="objIndicator"
    :chat="chat"
    :gauge="gauge"
    :teamId="teamId"
    colspan="13"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Dropdown, DropdownItem } from "./dropdown/";
import { KPI } from "../kpi";
import { Gems } from "../gems";
import MatrixTrRow from "./MatrixTrRow.vue";
import IndicatorBooleanVolume from "./IndicatorBooleanVolume.vue";
import { useTableMetric } from "../composables/useTableMetric";

export default {
  name: "MatrixIndicatorBlockNotEditable",
  setup () {
    return {
      useTableMetric
    }
  },
  props: {
    objIndicator: Object,
    teamId: [String, Number],
  },
  components: {
    Dropdown,
    DropdownItem,
    MatrixTrRow,
    IndicatorBooleanVolume,
  },
  data() {
    return {
      indicator: Object,
      direct: false,
      modalAtivo: false,
      isOpen: false,
      radio: Boolean,
      gauge: false,
      chat: false,
      Gems,
      KPI
    };
  },
  computed: {
    ...mapGetters(["PercentageState"]),
  },
  methods: {
    ...mapActions(['ShowGauge']),

    async Show(type) {
      const res = await this.ShowGauge(
        {
          gauge: this.gauge,
          chat: this.chat,
          commentIcon: this.$refs.commentIcon,
          gaugeIcon: this.$refs.gaugeIcon,
          indicator: this.$refs.indicator,
          type
        }
      )

      this.gauge = res[0]
      this.chat = res[1]
    },

    Toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  async mounted() {
    if(this.objIndicator.ind_met_id == 7) {
      await this.useTableMetric(this.objIndicator)

      if(this.objIndicator.direct_matching) {
        this.objIndicator.METRICS.forEach((metric, i) => {
          if(metric.evaluation == this.objIndicator.ind_vol)
          this.selectedMetric = i
        })
      }
    }

    let arr = KPI.GetMatrixArrTooltips(this.$refs, this.objIndicator);
    KPI.CheckForRefsTooltips(arr);
  },
  updated() {
    let arr = KPI.GetMatrixArrTooltips(this.$refs, this.objIndicator);
    KPI.CheckForRefsTooltips(arr);
  },
};
</script>

<style scoped>

input {
  text-align: center;
}

.txt-result {
  border: none;
}
</style>
