<template>
  <div class="options">
    <input type="text" class="search" :class="{'search-not-admin': !admin}" ref="txtName" @keyup="FilterSearchUsers()" placeholder="Pesquisar colaborador..." />

    <div ref="toggle" class="show-all" v-if="admin">
      <input type="checkbox" id="switch" class="cursor-pointer" @click="SwitchToggle" v-model="toggle" />
      <label for="switch" class="cursor-pointer">MOSTRAR INATIVOS</label>
    </div>
  </div>
  <div class="users-list">
    <div class="sitemap"
      :class="{hidden: $refs.txtName != null && $refs.txtName.value.length > 0}"
      v-show="Object.keys(sitemap).length > 0"
    >
      <div v-for="(row, indexr) in sitemap" :key="indexr">
        <div v-for="(headship, index) in row" :key="index">
          <span class="name-container">
            <span class="headship"
              :class="IsClickable(headship)"
              @click="OpenSubordinateMap(headship, indexr)"
            >{{ headship.professional_name }}
            </span>
            <span v-if="++index < Object.keys(row).length">
              <i class="fas fa-chevron-right icon" aria-hidden="true" ></i>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="table-user" :key="reload">
      <table class="table-user">
        <tbody>
          <UserBlock
            v-for="user in toggle ? arrUsers : arrUsersActive"
            :key="user.id"
            :objUser="user"
            :userPage="true"
            :showInactives="toggle"
            @detail="OpenDetail($event)"
            @subordinate="OpenSubordinate($event)"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import UserBlock from "../components/UserBlock.vue";
import { mapGetters, mapActions } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "users",
  emits: ["force-reload"],
  components: {
    UserBlock,
  },
  data() {
    return {
      localUser: false,
      id: Number,
      toggle: false,
      admin: Boolean,
      sitemap: [],
      subordinates: [],
      originalUser: Number,
      arrUsers: Array,
      arrUsersActive: Array,
      actualSubordinate: null,
      reload: false,
      canClick: false,
      logged: "",
      abortController: null,
    };
  },
  computed: {
    ...mapGetters(["idStore"]),
  },
  methods: {
    ...mapActions(["StoreSwitch", "StoreId"]),

    async GetHierarchy(user_id) {
      const response = await KPI.CallBackEnd("get", "get-hierarchy", {
        params: {
          user_id: user_id,
        },
      })

      if(response.data.error) {
        Gems.Telegram('Ocorreu um erro', 'tele-not-allowed')
        return
      }

      this.CreateUsersArr(response.data)
      this.reload = !this.reload
    },

    async SwitchToggle() {
      this.toggle = !this.toggle;
    },

    FilterSearchUsers() {
      if (this.$refs.txtName.value != "") {
        this.SearchUsers(this.actualSubordinate);
      } else {
        if (Object.keys(this.sitemap).length > 0) {
          this.GetHierarchy(this.actualSubordinate);
        } else {
          this.GetHierarchy(null);
        }
      }
    },

    async SearchUsers(user_id) {
      if (this.abortController != null) this.abortController.abort();

      this.abortController = new AbortController();

      const response = await KPI.CallBackEnd("get", "filter-hierarchy", {
        params: {
          user_id: user_id,
          search_text: this.$refs.txtName.value,
          cycle: localStorage.getItem("cycle"),
          signal: this.abortController.signal,
        },
      })

      if(response.data.error) {
        Gems.Telegram('Ocorreu um erro', 'tele-not-allowed')
        return
      }

      this.logged = response.data[1]
      this.CreateUsersArr(response.data[0])
      this.reload = !this.reload
    },

    CreateUsersArr(usersArr) {
      this.arrUsersActive = [];
      for (let user in usersArr) {
        usersArr[user]["refShow"] = `show${usersArr[user]["user_id"]}`;
        usersArr[user]["refSummary"] = `summary${usersArr[user]["user_id"]}`;
        usersArr[user]["refMatrix"] = `matrix${usersArr[user]["user_id"]}`;
        usersArr[user]["refTeams"] = `teams${usersArr[user]["user_id"]}`;
        usersArr[user]["refInternal"] = `internal${usersArr[user]["user_internal"]}`;

        // usersArr[user]["sitemap"].shift();
        if (usersArr[user]["active"] == 1) {
          this.arrUsersActive.push(usersArr[user]);
        }
      }
      this.arrUsers = usersArr;
    },

    OpenSubordinate(headship) {
      if (
        Object.keys(this.sitemap).length > 0 &&
        this.$refs.txtName.value == ""
      ) {
        for (let sitemap in headship["sitemap"]) {
          for (let row in headship["sitemap"][sitemap]) {
            if (
              headship.user_id == headship["sitemap"][sitemap][row]["user_id"]
            ) {
              for (let map in this.sitemap) {
                this.sitemap[map].push(headship["sitemap"][sitemap][row]);
              }
            }
          }
        }
      } else {
        this.sitemap = headship["sitemap"];
      }
      this.arrUsers = [];
      this.actualSubordinate = headship.user_id;
      this.GetHierarchy(headship.user_id);
      this.$refs.txtName.value = "";
    },

    OpenSubordinateMap(headship, index) {
      if (this.admin != true) {
        if (headship.clickable != true) {
          return;
        }
      }
      this.actualSubordinate = headship.user_id;
      this.GetHierarchy(headship.user_id);
      // this.sitemap = this.sitemap[index];
      for (let row in this.sitemap) {
        if (row != index) {
          delete this.sitemap[row];
        }
      }
      for (let user in this.sitemap[index]) {
        if (this.sitemap[index][user]["user_id"] == headship.user_id) {
          let soma = parseInt(user, 10) + 1;
          let count = Object.keys(this.sitemap[index]).length;
          let max = count - soma;
          this.sitemap[index].splice(soma, max);
        }
      }
    },

    OpenDetail(user) {
      this.$router.push({ name: "profile", state: { id: user.user_id } });
    },

    IsClickable(user) {
      if (this.admin == true && user.user_id != null) 
        return "clickable"

      if (user.user_id == null)
        return "not-clickable"

      if (user.clickable == true) 
        return "clickable"
      else 
        return "not-clickable"
    },
  },
  mounted() {
    this.GetHierarchy(null)
    this.id = this.idStore
    this.admin = KPI.isAdmin()
      
    Gems.Tooltip(this.$refs.toggle, "Marque para ver os utilizadores inativos", "tooltip")
  },
};
</script>

<style scoped>
.options {
  padding: 15px 16px 15px 16px;
  height: 50px;
  display: flex;
  text-align: center;
  vertical-align: middle;
}

.search {
  width: 86%;
  height: 25px;
}

.search-not-admin {
  width: 100% !important;
}

.show-all {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 35px;
}

.show-all label {
  font-size: 0.8em;
}

.show-all label {
  font-weight: 500;
}

.users-list {
  padding: 10px 16px 0px 16px;
}

.sitemap {
  display: flex;
  background-color: var(--special-app-color);
  border: solid 1px var(--light-gray-color);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  justify-content: space-between;
  font-weight: 600;
}

.sitemap div {
  display: flex;
  flex-wrap: wrap;
}

.sitemap .headship:hover {
  color: var(--default-app-color);
  cursor: pointer;
}

.icon {
  padding-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
}

#switch {
  margin: 0px 8px 0px 0px;
  transform: scale(1.3);
}

.not-clickable {
  pointer-events: none;
}

.name-container {
  height: 17px;
  margin: auto !important;
}

.table-user {
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .search {
    width: 82%;
  }
}
</style>
