<template>
  <div class="div-master">
    <MenuList
      :propArrMenu="arrMenus"
      :arrCycle="ArrCycle"
      class="menu"
      :key="ReloadMenuState"
      @force-reload="Reload()"
    />
    <div class="div-content">
      <router-view :key="reload" @force-reload="ReloadMenu()" />
    </div>
  </div>
</template>

<script>
import MenuList from "../components/MenuList";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "master",
  props: ["arrCycle"],
  components: {
    MenuList,
  },
  data() {
    return {
      arrMenus: [],
      showSummary: false,
      reload: 0,
      reloadMenu: false,
    };
  },
  computed: { ...mapGetters(["ReloadMenuState", "ArrCycle"]) },
  watch: {
    ReloadMenuState: async function () {
      await this.GetMenuInfo(JSON.parse(localStorage.getItem("cycle")));
      this.showSummary = this.ArrCycle["summary_self"];
      this.ShowSummary();
    },
  },
  methods: {
    ...mapActions(["ReloadMenu", "GetMenuInfo", "StoreSitemap"]),

    ShowSummary() {
      let summaryIndex = this.arrMenus.findIndex((menu) => menu.id == 8);
      if (this.showSummary == 0) {
        if (summaryIndex != -1) {
          this.arrMenus.splice(summaryIndex, 1);
        }
      } else {
        if (summaryIndex == -1) {
          this.arrMenus.splice(2, 0, {
            id: 8,
            text: "Síntese",
            url: "",
            toView: "/summary",
            icon: "fa fa-chart-pie",
            requiresAdmin: false,
            isRouter: true,
          });
        }
      }
    },

    SettingsLayout() {
      return this.$route.meta.settingsLayout === true ? true : false;
    },

    Reload() {
      history.state.userId = null;
      history.state.id = null;
      this.reload = !this.reload;
      this.StoreSitemap([])
    },
  },
  async created() {
    await this.GetMenuInfo(JSON.parse(localStorage.getItem("cycle")));

    let end = null

    this.showSummary = this.ArrCycle["summary_self"]
    let today = new Date().toISOString().slice(0, 10)

    if(this.ArrCycle["cycle_end_original"])
      end = new Date(this.ArrCycle["cycle_end_original"]).toISOString().slice(0, 10)

    let cycle_finished = today > end

    localStorage.setItem("cycle_finished", cycle_finished)

    document.body.style.backgroundColor = "var(--light-gray-color)";

    this.arrMenus = [
      {
        id: 1,
        text: "Admin",
        url: "",
        toView: "/admin",
        icon: "fas fa-cogs",
        requiresAdmin: true,
        isRouter: true,
      },
      {
        id: 2,
        text: "Matriz Desempenho",
        url: "",
        toView: "/matrix",
        icon: "fas fa-tachometer-alt",
        requiresAdmin: false,
        isRouter: true,
      },
      {
        id: 4,
        text: "Utilizadores",
        url: "",
        toView: "/users",
        icon: "fas fa-user-friends",
        requiresAdmin: false,
        isRouter: true,
      },
      {
        id: 3,
        text: "Equipas",
        url: "",
        toView: "/user-teams",
        icon: "fas fa-users",
        requiresAdmin: false,
        isRouter: true,
      },
      {
        id: 5,
        text: "Relatório",
        url: "",
        toView: "/reports",
        icon: "fas fa-chart-bar",
        isRouter: true,
      },
      {
        id: 6,
        text: "Ajuda",
        url: "",
        toView: "/help",
        icon: "fas fa-question-circle",
        requiresAdmin: false,
        isRouter: false,
      },
      {
        id: 6,
        text: "Privacidade",
        url: "",
        toView: "/privacy",
        icon: "fas fa-user-shield",
        requiresAdmin: false,
        isRouter: true,
      },
      {
        id: 7,
        text: "Sair",
        url: "",
        toView: "/logout",
        icon: "fas fa-sign-out-alt logout",
        requiresAdmin: false,
        isRouter: true,
      },
    ];
    this.ShowSummary();
  },
};
</script>

<style scoped>
.div-master {
  width: 100%;
  height: 100%;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 6px 12px rgba(30, 60, 90, 0.3);
}

.menu {
  z-index: 0;
}

.div-content {
  width: 1041px;
  z-index: 0;
  padding: 1%;
}

@media print {
  .menu {
    display: none;
  }

  .div-content {
    width: 1150px;
  }
}

@media only screen and (max-width: 1400px) {
  .div-content {
    width: 850px;
  }
}
</style>
