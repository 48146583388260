<template>
  <div>
    <input
      type="text"
      class="txtFilter"
      v-model="searchText"
      @keyup="SearchIndicators()"
      placeholder="Filtrar..."
    />
    <SearchIndicatorList
      :arrIndicators="arrIndicators"
      :cycle="cycle"
      :team="team"
    />
  </div>
</template>

<script>
import SearchIndicatorList from "./SearchIndicatorList";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "SearchIndicator",
  props: {
    templateID: [String, Number],
    cycle: Boolean,
    cycleId: [String, Number],
    team: Boolean,
  },
  components: {
    SearchIndicatorList,
  },
  methods: {
    async GetIndicators(template_id) {
      if (this.cycle == true) {
        try {
          const response = await KPI.CallBackEnd("get", "get-operations", {
            params: { get: "cycle-indicators", cycle: this.cycleId },
          });

          this.arrIndicatorsAll = response.data;
          this.arrIndicatorsAll.forEach((elem) => (elem.visible = ""));
          this.arrIndicators = this.arrIndicatorsAll;
        } catch (error) {
          Gems.Telegram(error, "tele-not-allowed");
        }
      } else {
        if (this.team == true) {
          try {
            const response = await KPI.CallBackEnd("get", "get-operations", {
              params: { get: "team-indicators-list", team: template_id },
            });

            this.arrIndicatorsAll = response.data;
            this.arrIndicatorsAll.forEach((elem) => (elem.visible = ""));
            this.arrIndicators = this.arrIndicatorsAll;
          } catch (error) {
            Gems.Telegram(error, "tele-not-allowed");
          }
        } else {
          try {
            const response = await KPI.CallBackEnd("get", "get-operations", {
              params: { get: "indicators", temp_id: template_id },
            });
            this.arrIndicatorsAll = response.data;
            this.arrIndicatorsAll.forEach((elem) => (elem.visible = ""));
            this.arrIndicators = this.arrIndicatorsAll;
          } catch (error) {
            Gems.Telegram(error, "tele-not-allowed");
          }
        }
      }
    },

    SearchIndicators() {
      const arrFiltered = this.arrIndicatorsAll.filter(
        (elem) =>
          elem.ind_desig
            .trim()
            .toUpperCase()
            .search(this.searchText.trim().toUpperCase()) > -1
      );

      this.arrIndicators.forEach(function (indicator) {
        indicator.visible = "display: none";
        arrFiltered.forEach(function (filtered) {
          if (indicator.ind_id == filtered.ind_id) {
            indicator.visible = "";
          }
        });
      });
    },
  },
  data() {
    return {
      arrIndicatorsAll: [],
      arrIndicators: [],
      searchText: "",
    };
  },
  mounted() {
    this.GetIndicators(this.templateID);
  },
};
</script>

<style scoped>
.txtFilter {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid var(--medium-gray-color);
  border-radius: 4px;
  font-size: 1em;
}
</style>
