<template>
  <div class="divContent">
    <form @submit.prevent="ConfirmCreateTemplate(txtDesig)">
      <div>Descritor</div>

      <input type="text" class="txtDesig" v-model="txtDesig" required />
      <button type="submit" class="btnCreate btn-action">CRIAR MODELO</button>

      <div>Descritivo</div>
      <div>
        <input type="text" class="txtDesc" v-model="txtDesc" />
      </div>
    </form>
    <div class="divPadding">
      <TemplatesPreviewList
        :arrTemplates="arrTemplates"
        @clicked="TriggeredFromChild"
      />
    </div>
  </div>
</template>

<script>
import TemplatesPreviewList from "../components/TemplatesPreviewList";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "templates",
  components: {
    TemplatesPreviewList,
  },
  data() {
    return {
      arrTemplates: [],
      txtDesig: "",
      txtDesc: "",
    };
  },
  methods: {
    async GetTemplates() {
      try {
        const response = await KPI.CallBackEnd("get", "get-templates", {
          params: { cycle_id: localStorage.getItem("cycle") },
        });
        this.arrTemplates = response.data;
      } catch (error) {
        Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed");
      }
    },

    async ConfirmCreateTemplate(designation) {
      if (designation) {
        for (let template in this.arrTemplates) {
          if (this.arrTemplates[template]["temp_desig"] == designation) {
            Gems.Telegram(
              "Já existe um modelo com este nome",
              "tele-not-allowed"
            );
            return;
          }
        }
        this.CreateTemplate();
      }
    },

    async CreateTemplate() {
      const response = await KPI.CallBackEnd("post", "create-template", {
        desig: this.txtDesig,
        desc: this.txtDesc,
        cycle: localStorage.getItem("cycle"),
      })

      if (response.status != 200) {
        Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed")
        return
      }

      Gems.Telegram("Modelo criado", "tele-success")
      this.GetTemplates()
    },

    TriggeredFromChild(value) {
      if (value === "RELOAD_TEMPLATES") {
        this.GetTemplates();
      }
    },
  },
  created() {
    this.GetTemplates();
  },
};
</script>

<style>

@media only screen and (max-width: 1400px) {
  .divPadding .card {
    width: 144px;
  }
}
</style>

<style scoped>
.txtDesig {
  width: 81%;
  margin-right: 2%;
}
.txtDesc {
  width: 98%;
}
.btnCreate {
  width: 15%;
  height: 29px;
  vertical-align: top;
}
.divContent {
  margin: 10px;
  width: 98%;
}

</style>
