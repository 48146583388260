<template>
  <div class="divMain">
    <!-- A MINHA EMPRESA -->
    <section class="group" id="my-entity">
      <div id="" class="report-header">
        <span class="txt-rules">A MINHA EMPRESA</span>
        <div class="div-print">
          <button id="print-report" class="invisible" @click="Print">
            IMPRIMIR
          </button>
        </div>
      </div>
      <div id="my-entity-container" class="invisible">
        <div class="entity-data">
          <div class="margin-table">
            <div class="report-label">Colaboradores</div>
            <div id="count-employees" class="report-value">
              {{ entity.total_employees ?? "--" }}
            </div>
          </div>

          <div class="margin-table">
            <div class="report-label">Avaliados</div>
            <div id="" class="report-value">
              {{ entity.total_employees_evaluated ?? "--" }}
            </div>
          </div>

          <div class="margin-table">
            <div class="report-label">Taxa de Cobertura da Avaliação</div>
            <div class="report-value">
              {{
                entity.evaluated_percent ? `${Gems.FormatDecimals(entity.evaluated_percent, 2)} %` : "--"
              }}
            </div>
          </div>

          <div v-if="entity.avg_integrated_eval != null" class="margin-table">
            <div class="report-label">Avaliação Média [Integrada]</div>
            <div class="report-value">
              {{ entity.avg_integrated_eval != '--' ? `${Gems.FormatDecimals(entity.avg_integrated_eval, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.avg_spec_eval != null" class="margin-table">
            <div class="report-label">Avaliação Média [Específica]</div>
            <div class="report-value">
              {{ entity.avg_spec_eval != '--' ? `${Gems.FormatDecimals(entity.avg_spec_eval, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.avg_weighted_eval != null" class="margin-table">
            <div class="report-label">Avaliação Média [Ponderada]</div>
            <div class="report-value">
              {{ entity.avg_weighted_eval != '--' ? `${Gems.FormatDecimals(entity.avg_weighted_eval, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.integrated_median != null" class="margin-table">
            <div class="report-label">Mediana [Integrada]</div>
            <div class="report-value">
              {{ entity.integrated_median != '--' ? `${Gems.FormatDecimals(entity.integrated_median, 2)} %` : '--' }}
            </div>
          </div>
          <div v-if="entity.spec_median != null" class="margin-table">
            <div class="report-label">Mediana [Específica]</div>
            <div class="report-value">
              {{ entity.spec_median != '--' ? `${Gems.FormatDecimals(entity.spec_median, 2)} %` : '--' }}
            </div>
          </div>
          <div v-if="entity.weighted_median != null" class="margin-table">
            <div class="report-label">Mediana [Ponderada]</div>
            <div class="report-value">
              {{ entity.weighted_median != '--' ? `${Gems.FormatDecimals(entity.weighted_median, 2)} %` : '--' }}
            </div>
          </div>

          <div
            v-if="entity.std_integrated_deviation != null"
            class="margin-table"
          >
            <div class="report-label">Desvio Padrão [Integrado]</div>
            <div class="report-value">
              {{ entity.std_integrated_deviation != '--' ? `${Gems.FormatDecimals(entity.std_integrated_deviation, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.std_spec_deviation != null" class="margin-table">
            <div class="report-label">Desvio Padrão [Específico]</div>
            <div class="report-value">
              {{ entity.std_spec_deviation != '--' ? `${Gems.FormatDecimals(entity.std_spec_deviation, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.std_weighted_deviation != null" class="margin-table">
            <div class="report-label">Desvio Padrão [Ponderado]</div>
            <div class="report-value">
              {{ entity.std_weighted_deviation != '--' ? `${Gems.FormatDecimals(entity.std_weighted_deviation, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.min_integrated_eval != null" class="margin-table">
            <div class="report-label">Avaliação Mínima [Integrada]</div>
            <div class="report-value">
              {{ entity.min_integrated_eval != '--' ? `${Gems.FormatDecimals(entity.min_integrated_eval, 2)} %` : '--' }}
            </div>
          </div>
          <div v-if="entity.min_spec_eval != null" class="margin-table">
            <div class="report-label">Avaliação Mínima [Específica]</div>
            <div class="report-value">
              {{ entity.min_spec_eval != '--' ? `${Gems.FormatDecimals(entity.min_spec_eval, 2)} %` : '--' }}
            </div>
          </div>
          <div v-if="entity.min_weighted_eval != null" class="margin-table">
            <div class="report-label">Avaliação Mínima [Ponderada]</div>
            <div class="report-value">
              {{ entity.min_weighted_eval != '--' ? `${Gems.FormatDecimals(entity.min_weighted_eval, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.max_integrated_eval != null" class="margin-table">
            <div class="report-label">Avaliação Máxima [Integrada]</div>
            <div class="report-value">
              {{ entity.max_integrated_eval != '--' ? `${Gems.FormatDecimals(entity.max_integrated_eval, 2)} %` : '--' }}
            </div>
          </div>
          <div v-if="entity.max_spec_eval != null" class="margin-table">
            <div class="report-label">Avaliação Máxima [Específica]</div>
            <div class="report-value">
              {{ entity.max_spec_eval != '--' ? `${Gems.FormatDecimals(entity.max_spec_eval, 2)} %` : '--' }}
            </div>
          </div>
          <div v-if="entity.max_weighted_eval != null" class="margin-table">
            <div class="report-label">Avaliação Máxima [Ponderada]</div>
            <div class="report-value">
              {{ entity.max_weighted_eval != '--' ? `${Gems.FormatDecimals(entity.max_weighted_eval, 2)} %` : '--' }}
            </div>
          </div>

          <div v-if="entity.integrated_amplitude != null" class="margin-table">
            <div class="report-label">Amplitude [Integrado]</div>
            <div class="report-value">
              {{ entity.integrated_amplitude != '--' ? Gems.FormatDecimals(entity.integrated_amplitude, 2) : "--" }}
            </div>
          </div>

          <div v-if="entity.spec_amplitude != null" class="margin-table">
            <div class="report-label">Amplitude [Específico]</div>
            <div class="report-value">
              {{ entity.spec_amplitude != '--' ? Gems.FormatDecimals(entity.spec_amplitude, 2) : "--" }}
            </div>
          </div>

          <div v-if="entity.weighted_amplitude != null" class="margin-table">
            <div class="report-label">Amplitude [Ponderado]</div>
            <div class="report-value">
              {{ entity.weighted_amplitude != '--' ? Gems.FormatDecimals(entity.weighted_amplitude, 2) : "--" }}
            </div>
          </div>
        </div>

        <div id="chart-entity" class="chart-container">
          <div class="div-charts-select-container">
            <label class="txt-order-by">Gráficos: </label>
            <select v-model="showEntityChart">
              <option :value="null">--</option>
              <option value="1" v-show="entity.avg_integrated_eval != null">Integrado</option>
              <option value="2" v-show="entity.avg_spec_eval != null">Específico</option>
              <option value="3" v-show="entity.avg_weighted_eval != null">Ponderado</option>
            </select>
          </div>

          <canvas id="entity-integrated" v-show="showEntityChart == 1"></canvas>
          <canvas id="entity-spec" v-show="showEntityChart == 2"></canvas>
          <canvas id="entity-weighted" v-show="showEntityChart == 3"></canvas>
        </div>
      </div>
    </section>

    <!-- ANÁLISE DE GÉNEROS E IGUALDADE -->
    <section class="group" id="genders-equalty">
      <div id="" class="report-header">
        <span class="txt-rules">ANÁLISE DE GÉNERO E IGUALDADE</span>
      </div>

      <table class="report-table invisible" id="genders-equalty-container">
        <thead class="report-table-header">
          <tr>
            <th></th>
            <th v-if="entity.avg_integrated_eval != null">
              Av. Méd. [Integrada]
            </th>
            <th v-if="entity.avg_spec_eval != null">Av. Méd. [Específica]</th>
            <th v-if="entity.avg_weighted_eval != null">
              Av. Méd. [Ponderada]
            </th>
            <th>N. Indivíduos</th>
            <th></th>
          </tr>
        </thead>

        <tbody
          class="report-table-body"
          id="genders-body"
          v-for="(gender, i) in genders"
          :key="i"
        >
          <tr>
            <td>{{ gender.designation ?? "--" }}</td>
            <td v-if="gender.user_integrated_eval != null">
              {{ gender.user_integrated_eval != '--' ? Gems.FormatDecimals(gender.user_integrated_eval, 2) : '--' }}
            </td>
            <td v-if="gender.user_spec_eval != null">
              {{ gender.user_spec_eval != '--' ? Gems.FormatDecimals(gender.user_spec_eval, 2) : '--' }}
            </td>
            <td v-if="gender.user_weighted_eval != null">
              {{ gender.user_weighted_eval != '--' ? Gems.FormatDecimals(gender.user_weighted_eval, 2) : '--' }}
            </td>
            <td>{{ gender.total_gender ?? "--" }}</td>
            <td>{{ gender.percent != '--' ? `${Gems.FormatDecimals(gender.percent, 2)} %` : "--" }}</td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- AS MINHAS EQUIPAS ??? -->
    <section class="group" id="my-teams">
      <div class="report-header">
        <span class="txt-rules">AS MINHAS EQUIPAS</span>
      </div>

      <div id="my-teams-container" class="invisible">
        <div class="team-container" v-for="(team, i) in teams" :key="i">
          <div id="team-header" class="margin-table team-header default-app-bg">
            <div class="row team-name">
              {{ team.designation ?? "--" }}
            </div>
            <div class="row">
              <div class="col">
                <div class="team-header-col">
                  <div class="report-label">Colaboradores</div>
                  <div class="report-value">
                    {{ team.total_employees ?? "--" }}
                  </div>
                </div>
                <div class="team-header-col">
                  <div class="report-label">Avaliados</div>
                  <div class="report-value">
                    {{ team.total_employees_evaluated ?? "--" }}
                  </div>
                </div>

                <div
                  v-if="team.avg_integrated_eval != null"
                  class="team-header-col"
                >
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Avaliação Integrada', 'tooltip')">Aval. Int.</div>
                  <div class="report-value">
                    {{ team.avg_integrated_eval != '--' ? `${Gems.FormatDecimals(team.avg_integrated_eval, 2)} %` : '--' }}
                  </div>
                </div>
                <div v-if="team.avg_spec_eval != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Avaliação Específica', 'tooltip')">Aval. Esp.</div>
                  <div class="report-value">
                    {{ team.avg_spec_eval != '--' ? `${Gems.FormatDecimals(team.avg_spec_eval, 2)} %` : '--' }}
                  </div>
                </div>
                <div
                  v-if="team.avg_weighted_eval != null"
                  class="team-header-col"
                >
                  <div class="report-label"  @mouseenter="Gems.Tooltip($event.target, 'Avaliação Ponderada', 'tooltip')">Aval. Pond.</div>
                  <div class="report-value">
                    {{ team.avg_weighted_eval != '--' ? `${Gems.FormatDecimals(team.avg_weighted_eval, 2)} %` : '--' }}
                  </div>
                </div>

                <div v-if="team.median_integrated != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mediana Integrada', 'tooltip')">Mediana Int.</div>
                  <div class="report-value">
                    {{ team.median_integrated != '--' ? `${Gems.FormatDecimals(team.median_integrated, 2)} %` : '--'}}
                  </div>
                </div>
                <div v-if="team.median_spec != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mediana Específica', 'tooltip')">Mediana Esp.</div>
                  <div class="report-value">
                    {{ team.median_spec != '--' ? `${Gems.FormatDecimals(team.median_spec, 2)} %` : '--' }}
                  </div>
                </div>
                <div v-if="team.median_weighted != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mediana Ponderada', 'tooltip')">Mediana Pond.</div>
                  <div class="report-value">
                    {{ team.median_weighted != '--' ? `${Gems.FormatDecimals(team.median_weighted, 2)} %` : '--' }}
                  </div>
                </div>

                <div v-if="team.min_integrated_eval != null" class="team-header-col" >
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mínima Integrada', 'tooltip')">Mín. Int.</div>
                  <div class="report-value">
                    {{ team.min_integrated_eval != '--' ? `${Gems.FormatDecimals(team.min_integrated_eval, 2)} %` : '--' }}
                  </div>
                </div>
                <div v-if="team.min_spec_eval != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mínima Específica', 'tooltip')">Mín. Esp.</div>
                  <div class="report-value">
                    {{ team.min_spec_eval != '--' ? `${Gems.FormatDecimals(team.min_spec_eval, 2)} %` : '--' }}
                  </div>
                </div>
                <div
                  v-if="team.min_weighted_eval != null"
                  class="team-header-col"
                >
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mínima Ponderada', 'tooltip')">Mín. Pond.</div>
                  <div class="report-value">
                    {{ team.min_weighted_eval != '--' ? `${Gems.FormatDecimals(team.min_weighted_eval, 2)} %` : '--' }}
                  </div>
                </div>

                <div
                  v-if="team.max_integrated_eval != null"
                  class="team-header-col"
                >
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Máxima Integrada', 'tooltip')">Máx. Int.</div>
                  <div class="report-value">
                    {{ team.max_integrated_eval != '--' ? `${Gems.FormatDecimals(team.max_integrated_eval, 2)} %` : '--' }}
                  </div>
                </div>
                <div v-if="team.max_spec_eval != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Máxima Específica', 'tooltip')">Máx. Esp.</div>
                  <div class="report-value">
                    {{ team.max_spec_eval != '--' ? `${Gems.FormatDecimals(team.max_spec_eval, 2)} %` : '--' }}
                  </div>
                </div>
                <div v-if="team.max_weighted_eval != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Máxima Ponderada', 'tooltip')">Máx. Pond.</div>
                  <div class="report-value">
                    {{ team.max_weighted_eval != '--' ? `${Gems.FormatDecimals(team.max_weighted_eval, 2)} %` : '--' }}
                  </div>
                </div>

                <div v-if="team.amplitude_integrated_eval != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Amplitude Integrada', 'tooltip')">Amp. Int.</div>
                  <div class="report-value">
                    {{ team.amplitude_integrated_eval != '--' ? Gems.FormatDecimals(team.amplitude_integrated_eval, 2) : '--' }}
                  </div>
                </div>
                <div v-if="team.amplitude_spec_eval != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Amplitude Específica', 'tooltip')">Amp. Esp.</div>
                  <div class="report-value">
                    {{ team.amplitude_spec_eval != '--' ? Gems.FormatDecimals(team.amplitude_spec_eval, 2) : '--' }}
                  </div>
                </div>
                <div v-if="team.amplitude_weighted_eval != null" class="team-header-col">
                  <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Amplitude Ponderada', 'tooltip')">Amp. Pond.</div>
                  <div class="report-value">
                    {{ team.amplitude_weighted_eval != '--' ? Gems.FormatDecimals(team.amplitude_weighted_eval, 2) : '--' }}
                  </div>
                </div>
              </div>

              <div>
                <div class="report-value chevron cursor-pointer">
                  <i class="fas fa-chevron-down" @click="ToggleShowHideIcon($event, `team${team.id}`)"></i>
                </div>
              </div>
            </div>
          </div>
          <div :id="'team' + team.id" class="hidden">
          <div class="order-users">
            <label for="order-subordinates" class="txt-order-by">Ordenar: </label>
            <select class="ddl-order-subordinates" name="order-subordinates" @change="OrderUsers($event.target.value, team.users)">
              <option value="1" v-if="team.avg_integrated_eval != null">Integrado</option>
              <option value="2" v-if="team.avg_spec_eval != null">Específico</option>
              <option value="3" v-if="team.avg_weighted_eval != null">Ponderado</option>
            </select>
          </div>
          <div v-for="(user, usInd) in team.users" :key="usInd">

            <div class="margin-table teams-grid" v-if="usInd == 0">
              <div class="report-label internal">Nº Interno</div>
              <div class="report-label">Nome</div>
              <div class="report-label">Função</div>
              <div v-if="user.avg_integrated_eval != null" class="report-label">
                Av. [Integrada]
              </div>
              <div v-if="user.avg_spec_eval != null" class="report-label">
                Av. [Específica]
              </div>
              <div v-if="user.avg_weighted_eval != null" class="report-label">
                Av. [Ponderada]
              </div>
            </div>
            <div id="team-users" class="user margin-table team-body teams-grid">
              <div class="report-value internal">
                {{ user.internal ?? "--" }}
              </div>
              <div class="report-value line-clamp text-truncate">
                {{ user.prof_name ?? "--" }}
              </div>

              <div class="report-value line-clamp text-truncate">
                {{ user.job_name ?? "--" }}
              </div>
              <div v-if="user.avg_integrated_eval != null" class="report-value with-color">
                <div class="line-clamp text-truncate">
                  {{
                    user.avg_integrated_eval != '--'
                      ? `${Gems.FormatDecimals(user.avg_integrated_eval, 2)} % ${user.rating_integrated ?? "--"}`
                      : "--"
                  }}
                </div>
                <div
                  class="rating-color"
                  :class="`${user.rating_integrated_color}-quali-color-bg`"
                ></div>
              </div>
              <div v-if="user.avg_spec_eval != null" class="report-value with-color">
                <div class="line-clamp text-truncate">
                  {{ 
                    user.avg_spec_eval != '--'
                      ? `${Gems.FormatDecimals(user.avg_spec_eval, 2)} % ${user.rating_spec ?? "--"}`
                      : "--"
                  }}
                </div>
                <div class="rating-color" :class="`${user.rating_spec_color}-quali-color-bg`"
                ></div>
              </div>
              <div v-if="user.avg_weighted_eval != null" class="report-value with-color">
                <div class="line-clamp text-truncate">
                  {{
                    user.avg_weighted_eval != '--'
                      ? `${Gems.FormatDecimals(user.avg_weighted_eval, 2)} % ${
                          user.rating_weighted ?? "--"
                        }`
                      : "--"
                  }}
                </div>
                <div class="rating-color" :class="`${user.rating_weighted_color}-quali-color-bg`"></div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>

    <!-- OS MEUS SUBORDINADOS  ??? -->
    <section class="group" id="my-subordinates">
      <div id="" class="report-header">
        <span class="txt-rules">OS MEUS SUBORDINADOS</span>
      </div>

      <div id="my-subordinates-container" class="invisible">
        <div id="subordinates-header" class="subordinate-header default-app-bg">
          <div class="subordinates-row">
            <div class="hierarchy-header-col">
              <div class="report-label">Colaboradores</div>
              <div class="report-value">
                {{ hierarchy_header.total_employees ?? "--" }}
              </div>
            </div>
            <div class="hierarchy-header-col">
              <div class="report-label">Avaliados</div>
              <div class="report-value">
                {{ hierarchy_header.total_employees_evaluated ?? "--" }}
              </div>
            </div>

            <div
              v-if="hierarchy_header.avg_integrated_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Avaliação Integrada', 'tooltip')">Aval. Int.</div>
              <div class="report-value">
                {{ hierarchy_header.avg_integrated_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.avg_integrated_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div
              v-if="hierarchy_header.avg_spec_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Avaliação Específica', 'tooltip')">Aval. Esp.</div>
              <div class="report-value">
                {{ hierarchy_header.avg_spec_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.avg_spec_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div
              v-if="hierarchy_header.avg_weighted_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Avaliação Ponderada', 'tooltip')">Aval. Pond.</div>
              <div class="report-value">
                {{ hierarchy_header.avg_weighted_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.avg_weighted_eval, 2)} %` : '--' }}
              </div>
            </div>

            <div
              v-if="hierarchy_header.integrated_median != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mediana Integrada', 'tooltip')">Mediana Int.</div>
              <div class="report-value">
                {{ hierarchy_header.integrated_median != '--' ? `${Gems.FormatDecimals(hierarchy_header.integrated_median, 2)} %` : '--' }}
              </div>
            </div>
            <div
              v-if="hierarchy_header.spec_median != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mediana Específica', 'tooltip')">Mediana Esp.</div>
              <div class="report-value">
                {{ hierarchy_header.spec_median != '--' ? `${Gems.FormatDecimals(hierarchy_header.spec_median, 2)} %` : '--' }}
              </div>
            </div>
            <div
              v-if="hierarchy_header.weighted_median != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mediana Ponderada', 'tooltip')">Mediana Pond.</div>
              <div class="report-value">
                {{ hierarchy_header.weighted_median != '--' ? `${Gems.FormatDecimals(hierarchy_header.weighted_median, 2)} %` : '--' }}
              </div>
            </div>

            <div
              v-if="hierarchy_header.min_integrated_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mínima Integrada', 'tooltip')">Mín. Int.</div>
              <div class="report-value">
                {{ hierarchy_header.min_integrated_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.min_integrated_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div
              v-if="hierarchy_header.min_spec_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mínima Específica', 'tooltip')">Mín. Esp.</div>
              <div class="report-value">
                {{ hierarchy_header.min_spec_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.min_spec_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div
              v-if="hierarchy_header.min_weighted_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Mínima Ponderada', 'tooltip')">Mín. Pond.</div>
              <div class="report-value">
                {{ hierarchy_header.min_weighted_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.min_weighted_eval, 2)} %` : '--' }}
              </div>
            </div>

            <div
              v-if="hierarchy_header.max_integrated_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Máxima Integrada', 'tooltip')">Max. Int.</div>
              <div class="report-value">
                {{ hierarchy_header.max_integrated_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.max_integrated_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div
              v-if="hierarchy_header.max_spec_eval != null"
              class="hierarchy-header-col"
            >
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Máxima Específica', 'tooltip')">Max. Esp.</div>
              <div class="report-value">
                {{ hierarchy_header.max_spec_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.max_spec_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div v-if="hierarchy_header.max_weighted_eval != null" class="hierarchy-header-col">
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Máxima Ponderada', 'tooltip')">Max. Pond.</div>
              <div class="report-value">
                {{ hierarchy_header.max_weighted_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.max_weighted_eval, 2)} %` : '--' }}
              </div>
            </div>

            <div v-if="hierarchy_header.amplitude_integrated_eval != null" class="hierarchy-header-col">
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Amplitude Integrada', 'tooltip')">Amp. Int.</div>
              <div class="report-value">
                {{ hierarchy_header.amplitude_integrated_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.amplitude_integrated_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div v-if="hierarchy_header.amplitude_spec_eval != null" class="hierarchy-header-col">
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Amplitude Específica', 'tooltip')">Amp. Esp.</div>
              <div class="report-value">
                {{ hierarchy_header.amplitude_spec_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.amplitude_spec_eval, 2)} %` : '--' }}
              </div>
            </div>
            <div v-if="hierarchy_header.amplitude_weighted_eval != null" class="hierarchy-header-col">
              <div class="report-label" @mouseenter="Gems.Tooltip($event.target, 'Amplitude Ponderada', 'tooltip')">Amp. Pond.</div>
              <div class="report-value">
                {{ hierarchy_header.amplitude_weighted_eval != '--' ? `${Gems.FormatDecimals(hierarchy_header.amplitude_weighted_eval, 2)} %` : '--' }}
              </div>
            </div>            
          </div>
          <div>
            <div class="report-value chevron cursor-pointer">
              <i class="fas fa-chevron-down" @click="ToggleShowHideIcon($event, 'my-subordinates-body')"></i>
            </div>
          </div>
        </div>

        <div id="my-subordinates-body" class="hidden">
          <div class="order-users">
            <label for="order-subordinates" class="txt-order-by">Ordenar: </label>
            <select class="ddl-order-subordinates" name="order-subordinates" @change="OrderUsers($event.target.value, hierarchy_users)">
              <option value="1" v-if="hierarchy_header.avg_integrated_eval != null">Integrado</option>
              <option value="2" v-if="hierarchy_header.avg_spec_eval != null">Específico</option>
              <option value="3" v-if="hierarchy_header.avg_weighted_eval != null">Ponderado</option>
            </select>
          </div>

          <div v-for="(user, i) in hierarchy_users" :key="i">
            <div class="margin-table subordinates-grid" v-if="i == 0" :class="GridSize(user)">
              <div class="report-label internal">Nº Interno</div>
              <div class="report-label">Nome</div>
              <div class="report-label">Função</div>
              <div class="report-label">Equipa</div>
              <div v-if="user.avg_integrated_eval != null" class="report-label">
                Av. [Integrada]
              </div>
              <div v-if="user.avg_spec_eval != null" class="report-label">
                Av. [Específica]
              </div>
              <div v-if="user.avg_weighted_eval != null" class="report-label">
                Av. [Ponderada]
              </div>
            </div>
            <div class="user margin-table subordinates-grid" :class="GridSize(user)">
              <div class="report-value internal">
                {{ user.internal ?? "--" }}
              </div>
              <div class="report-value line-clamp text-truncate">
                {{ user.prof_name ?? "--" }}
              </div>
              <div class="report-value line-clamp text-truncate">
                {{ user.job_name ?? "--" }}
              </div>

              <div class="report-value line-clamp text-truncate">
                {{ user.team_name ?? "--" }}
              </div>

              <div
                v-if="user.avg_integrated_eval != null"
                class="report-value with-color"
              >
                <div class="line-clamp text-truncate">
                  {{
                    user.avg_integrated_eval != '--'
                      ? `${Gems.FormatDecimals(user.avg_integrated_eval, 2)} % ${user.rating_integrated ?? "--"}`
                      : "--"
                  }}
                </div>
                <div
                  class="rating-color"
                  :class="`${user.rating_integrated_color}-quali-color-bg`"
                ></div>
              </div>

              <div
                v-if="user.avg_spec_eval != null"
                class="report-value with-color"
              >
                <div class="line-clamp text-truncate">
                  {{
                    user.avg_spec_eval != '--' 
                      ? `${Gems.FormatDecimals(user.avg_spec_eval, 2)} % ${user.rating_spec ?? "--"}`
                      : "--"
                  }}
                </div>
                <div
                  class="rating-color"
                  :class="`${user.rating_spec_color}-quali-color-bg`"
                ></div>
              </div>
              <div
                v-if="user.avg_weighted_eval != null"
                class="report-value with-color"
              >
                <div class="line-clamp text-truncate">
                  {{
                    user.avg_weighted_eval != '--'
                      ? `${Gems.FormatDecimals(user.avg_weighted_eval, 2)} % ${user.rating_weighted ?? "--"}`
                      : "--"
                  }}
                </div>
                <div
                  class="rating-color"
                  :class="`${user.rating_weighted_color}-quali-color-bg`"
                ></div>
              </div>
              <div
                class="report-value matrix-link cursor-pointer"
                :class="user.can_evaluate == 0 ? 'hidden' : ''"
                @click="
                  $router.push({ name: 'matrix', state: { userId: user.id }}) 
                "
              >
                <i class="fas fa-tachometer-alt"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="div-chart-header default-app-bg">
          <label class="txt-order-by">Gráficos</label>
          <i class="fas fa-chevron-down cursor-pointer" @click="ToggleShowHideIcon($event, 'div-hierarchy-chart-container')"></i>
        </div>

        <div class="div-hierarchy-charts">  
          <div id="div-hierarchy-chart-container" class="hidden">
            <div class="div-charts-select-container">
              <select v-model="showHierarchyChart">
                <option value="1" v-show="hierarchy_header.avg_integrated_eval != null">Integrado</option>
                <option value="2" v-show="hierarchy_header.avg_spec_eval != null">Específico</option>
                <option value="3" v-show="hierarchy_header.avg_weighted_eval != null">Ponderado</option>
              </select>
            </div>

            <div v-show="showHierarchyChart == 1">
              <div class="chart-hierarchy">
                <canvas id="hierarchy-integrated"></canvas>
              </div>
    
              <div class="chart-hierarchy-scroll">
                <div class="chart-hierarchy scroll">
                  <canvas id="hierarchy-integrated-stacked"></canvas>
                </div>
              </div>
            </div>
            
            <div v-show="showHierarchyChart == 2">
              <div class="chart-hierarchy">
                <canvas id="hierarchy-spec"></canvas>
              </div>
              
              <div class="chart-hierarchy-scroll">
                <div class="chart-hierarchy scroll">
                  <canvas id="hierarchy-spec-stacked"></canvas>
                </div>
              </div>
            </div>

            <div v-show="showHierarchyChart == 3">
              <div class="chart-hierarchy">
                <canvas id="hierarchy-weighted"></canvas>
              </div>

              <div class="chart-hierarchy-scroll">
                <div class="chart-hierarchy scroll">
                  <canvas id="hierarchy-weighted-stacked"></canvas>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";
import Chart from "chart.js/auto";

export default {
  name: "reports",
  data() {
    return {
      entity: [],
      entityCharts: [],
      genders: [],
      teams: [],
      hierarchy_header: [],
      hierarchy_users: [],
      hierarchyBarCharts: [],
      showEntityChart: null,
      showHierarchyChart: null,
      Gems,
    };
  },
  methods: {
    async GetReport() {
      const response = await KPI.CallBackEnd("get", "get-report", {
        params: {
          cycle_id: localStorage.getItem("cycle"),
        },
      })

      this.entity = response.data.entity.data
      this.entityCharts = response.data.entity.charts
      this.genders = response.data.genders
      this.teams = response.data.teams
      this.hierarchy_header = response.data.hierarchy_data["header"]
      this.hierarchy_users = response.data.hierarchy_data["users"]
      this.hierarchyBarCharts = response.data.hierarchy_data.charts

      document.getElementById("my-entity-container").classList.remove("invisible")
      document.getElementById("genders-equalty-container").classList.remove("invisible")
      document.getElementById("my-teams-container").classList.remove("invisible")
      document.getElementById("my-subordinates-container").classList.remove("invisible")
      document.getElementById("print-report").classList.remove("invisible")

      this.GenerateChart(document.getElementById('entity-integrated'), this.entityCharts.integrated, 'Distribuição Geral Classificações Empresa Avaliação Integrada')
      this.GenerateChart(document.getElementById('entity-weighted'), this.entityCharts.weighted, 'Distribuição Geral Classificações Empresa Avaliação Ponderada')
      this.GenerateChart(document.getElementById('entity-spec'), this.entityCharts.spec, 'Distribuição Geral Classificações Empresa Avaliação Específica')

      this.GenerateChart(document.getElementById('hierarchy-integrated'), this.hierarchyBarCharts.bar.integrated, 'Distribuição Geral Classificações Empresa Avaliação Integrada')
      this.GenerateChart(document.getElementById('hierarchy-integrated-stacked'), this.hierarchyBarCharts.stacked.integrated, 'Distribuição Geral Classificações Empresa Avaliação Integrada', true)

      this.GenerateChart(document.getElementById('hierarchy-weighted'), this.hierarchyBarCharts.bar.weighted, 'Distribuição Geral Classificações Empresa Avaliação Ponderada')
      this.GenerateChart(document.getElementById('hierarchy-weighted-stacked'), this.hierarchyBarCharts.stacked.weighted, 'Distribuição Geral Classificações Empresa Avaliação Ponderada', true)
      
      this.GenerateChart(document.getElementById('hierarchy-spec'), this.hierarchyBarCharts.bar.spec, 'Distribuição Geral Classificações Empresa Avaliação Específica')
      this.GenerateChart(document.getElementById('hierarchy-spec-stacked'), this.hierarchyBarCharts.stacked.spec, 'Distribuição Geral Classificações Empresa Avaliação Específica', true)
      
      if(this.hierarchy_header.avg_integrated_eval)
        this.showHierarchyChart = 1
      else if(this.hierarchy_header.avg_spec_eval)
        this.showHierarchyChart = 2
      else
        this.showHierarchyChart = 3
    },

    Print() {
      window.print();
    },

    ToggleShowHideIcon(iconEl, id) {
      if (iconEl.target.classList.contains("fa-chevron-up")) {
        iconEl.target.classList.remove("fa-chevron-up");
        iconEl.target.classList.add("fa-chevron-down");
        document.getElementById(id).classList.add("hidden");
      } else {
        iconEl.target.classList.remove("fa-chevron-down");
        iconEl.target.classList.add("fa-chevron-up");
        document.getElementById(id).classList.remove("hidden");
      }
    },

    OrderUsers(orderBy, users) {
      const bckUsers = users
      users = []
      if(orderBy == 1)
      bckUsers.sort((userA, userB) => {
          if(userA.avg_integrated_eval < userB.avg_integrated_eval)
            return 1

          if(userA.avg_integrated_eval > userB.avg_integrated_eval)
            return -1

          if(userA.avg_integrated_eval == userB.avg_integrated_eval)
          return 0
        })
      
      if(orderBy == 2)
        bckUsers.sort((userA, userB) => {
          if(userA.avg_spec_eval < userB.avg_spec_eval)
            return 1

          if(userA.avg_spec_eval > userB.avg_spec_eval)
            return -1

          if(userA.avg_spec_eval == userB.avg_spec_eval)
          return 0
        })

      if(orderBy == 3)
        bckUsers.sort((userA, userB) => {
          if(userA.avg_weighted_eval < userB.avg_weighted_eval)
            return 1

          if(userA.avg_weighted_eval > userB.avg_weighted_eval)
            return -1

          if(userA.avg_weighted_eval == userB.avg_weighted_eval)
          return 0
        })

        users = bckUsers
    },

    GridSize(user) {
      let count = 0
      if(user.avg_integrated_eval != null)
        count++

      if(user.avg_spec_eval != null)
        count++

      if(user.avg_weighted_eval != null)
        count++

      return `grid-${count}-eval`
    },

    GenerateChart(canvas, data, title, stacked  = false) {
      const myChart = new Chart(canvas, {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: title
            },
            legend: {
              display: stacked,
              position: "bottom"
            },
            tooltip: {
              displayColors: false,
            },
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                font: {
                  weight: "bold",
                },
              },
              grid: {
                display:false
              }
            },
            y: {
              stacked: true,
              beginAtZero: true,
              min: 0,
              type: 'linear'
            },
          },
        },
      });

      const containerBody = document.querySelectorAll('.scroll')
      const totalLabels = myChart.data.labels.length

      Array.from(containerBody).forEach(elem => {
        if(totalLabels > 7) {
          const newWidth = 700 + ((totalLabels - 7) * 30)
          elem.style.width = `${newWidth}px`
        }
      })
    }
  },
  created() {
    this.GetReport();
  },
  updated() {
    document
      .querySelectorAll(".user .line-clamp, .matrix-link")
      .forEach((userRow) => {
        if (userRow.className.includes("matrix-link")) {
          Gems.Tooltip(userRow, "Matriz", "tooltip");
        } else {
          Gems.Tooltip(userRow, userRow.innerText.trim(), "tooltip");
        }
      })
  },
};
</script>

<style scoped>
.divMain {
  margin: 0px 16px 16px 16px;
  text-align: center;
}

.group {
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--medium-gray-color);
}

.report-header {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.txt-rules {
  height: 20px;
}

.txt-rules, .txt-order-by {
  font-size: 1em;
  font-weight: bold;
}

.ddl-order-subordinates, .div-charts-select-container select {
  padding: 0px !important;
}

.margin-table {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-label {
  font-weight: bold;
  cursor: default;
}

.report-table {
  width: 100%;
  text-align: justify;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.team-header {
  flex-direction: column;
  align-items: normal;
}

.team-name {
  font-weight: 600;
  font-size: 1.5em;
  padding-left: 10px;
  border-bottom: 2px solid white;
}

.team-container:not(:first-child) {
  margin-top: 3px;
}

.teams-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 8% 20% 20% 15% 15% 15%;
  text-align: left;
}

.col {
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: unset;
  font-weight: normal;
  display: grid;
  grid-template-columns: 15% 12% 10% 10% 10% 10% 11% 11% 11%;
  flex: 1;
}

.col:nth-child(even) {
  margin-bottom: 5px;
}

.row {
  display: flex;
  align-items: center;
  color: white;
}

.subordinates-grid {
  display: grid;
  grid-auto-flow: column;
  text-align: left;
}

.subordinate-header {
  display: flex;
  align-items: center;
  color: white;
  padding: 5px;
}

.grid-1-eval {
  grid-template-columns: 7% 23% 24% 24% 20% 2%;
}

.grid-2-eval {
  grid-template-columns: 7% 19% 19% 19% 17% 17% 2%;
}

.grid-3-eval {
  grid-template-columns: 7% 16% 15% 15% 15% 15% 15% 2%;
}

.chevron {
  transform: scale(1.3);
  padding: 0px 5px;
}

.subordinates-row {
  display: grid;
  grid-template-columns: 15% 12% 10% 10% 10% 10% 11% 11% 11%;
  flex: 1;
}

.matrix-link {
  font-weight: bold;
}

.matrix-link:hover {
  color: var(--default-app-color);
}

.with-color {
  display: flex;
}

.with-color div:nth-child(1) {
  width: 80%;
}

.rating-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 3px;
}

.div-print {
  width: 150px;
}

#print-report {
  width: 100%;
}

.internal {
  text-align: center;
}

.order-users {
  text-align: end;
  margin-top: 10px;
}

.order-users label {
  margin-right: 5px;
}

.div-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  color: white;
  margin-top: 30px;
  margin-bottom: 10px;
}

#chart-entity {
  height: 350px;
  flex: 1;
  margin-left: 10px;
}

#my-entity-container {
  display: flex;
}

.chart-hierarchy {
  margin: 10px auto;
  max-height: 350px;
}

.chart-hierarchy-scroll {
  overflow-x: scroll;
}

.entity-data {
  flex: 1;
}

.div-charts-select-container {
  text-align: right;
}

.div-hierarchy-charts {
  width: 100%;
}

@media print {
  .div-print {
    display: none;
  }

  .matrix-link {
    display: none;
  }
}
</style>
