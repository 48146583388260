<template>
  <div class="dropdown-inner" v-if="interval == true">
    <button class="dropdown-interval line-clamp" @click.stop.prevent="$emit('toggle')"
        ref="item"
        @mouseenter="tooltip && Gems.Tooltip($refs.item, tooltip, 'tooltip')"
    >
      {{ text }}
    </button>

    <div class="dropdown-content-interval" v-show="isOpen" id="myDropdown">
      <slot />
    </div>
  </div>

  <div class="dropdown-inner"
      v-else>
    <button class="dropdown-btn cursor-pointer" @click.stop.prevent="$emit('toggle')"
        ref="item"
        @mouseenter="tooltip && Gems.Tooltip($refs.item, tooltip, 'tooltip')"
        >
      {{ text }}
    </button>

    <div class="dropdown-content" v-show="isOpen" id="myDropdown">
      <slot />
    </div>
  </div>
</template>

<script>
import { Gems } from '@/gems';

export default {
  name: "Dropdown",
  props: {
    text: {
      type: [String, Number],
      default: "Dropdown",
    },
    interval: Boolean,
    isOpen: Boolean,
    tooltip: String,
  },
  data () {
    return {
      Gems,
    }
  }
};
</script>

<style>
.dropdown-inner {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  width: 150px;
  border-radius: 4px;
  border: 2px solid black;
  background-color: white;
  color: black;
}

.dropdown-interval {
  width: 100px;
  height: 25px;
  line-height: 1lh;
}

.dropdown-interval:hover,
.dropdown-btn:hover {
  cursor: pointer;
}

.dropdown-btn:hover {
  color: gray;
  border-color: gray;
}

.dropdown-content {
  top: 27px;
  left: 19.8%;
  position: absolute;
  z-index: 1;
  width: 145px;
}

.dropdown-content-interval {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100px;
  z-index: 1;
}
</style>
