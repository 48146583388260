<template>
    
  <div class="card" :class="{'undraggable': !draggable }" :draggable="draggable">
    <div class="inner-card">
      <header :class="{'full-header': draggable}">
        <span class="line-clamp cursor-pointer text-truncate" ref="template" @mouseenter="Gems.TooltipIfNeeded($refs.template)">{{ objTemplate.temp_desig }}</span>
      </header>
      <div class="remove-icon" v-if="!draggable" >
        <i class="far fa-trash-alt" aria-hidden="true" @click.prevent="DeleteTemplate(objTemplate.temp_id, objTemplate.temp_desig)"></i>
      </div>
      <table>
        <thead>
          <tr>
            <td></td>
            <td>Peso</td>
          </tr>
        </thead>
        <tbody :key="scope.sco_id" v-for="scope in objTemplate['SCOPES']">
          <TemplatePreviewScope :objScope="IncludeScopes(scope)" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TemplatePreviewScope from "../components/TemplatesPreviewScope";

import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "TemplatesPreviewBlock",
  props: {
    objTemplate: Object,
    draggable: Boolean,
  },
  data() {
    return {
      Gems
    }
  },
  components: {
    TemplatePreviewScope,
  },
  methods: {
    IncludeScopes(scope) {
      if (scope.sco_weight < 1) {
        // scope.sco_desig = "--";
        scope.sco_weight = "--";
      }

      return scope;
    },

    async DeleteTemplate(template_id, template_desig) {
      Gems.Telegram(
        `Apagar o modelo ${template_desig} ?`,
        "tele-info",
        "",
        ""
      ).then(
        async () => {
          await KPI.CallBackEnd("post", "delete-template", { id: template_id })
          
          Gems.Telegram("Modelo apagado", "tele-success")
          this.$emit("clicked", "RELOAD_TEMPLATES")
        },
        () => {}
      );
    },
  },
};
</script>

<style scoped>

header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  width: 90%;
}

.full-header {
  width: 100% !important;
}

.undraggable {
  cursor: pointer;
}

.card {
  margin: 0 !important;
}

.inner-card {
  padding: 5px;
}

.inner-card header {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: black;
}

.remove-icon {
  text-align: end;
  margin-bottom: -17px;
}

.remove-icon i {
  position: relative;
  top: -17px;
}

</style>
