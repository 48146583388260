import { KPI } from "../../kpi";
import { Gems } from "../../gems";

const getDefaultState = () => {
  return {
    matrix: [],
    userEvaluation: [],
    hasNullVolume: false,
    entitiyHasNullVolume: false,
    userId: String,
    show: false,
    evaluationType: [],
    evaluationSelected: "",
    quali: [],
    isGaugeChanging: false,
    arrcomp: null,
    total_comp: null,
    total_360: null,
    arr360: null,
    balance_weight: true,
  };
};

const state = getDefaultState();

const getters = {
  MatrixEvaluation: (state) => state.userEvaluation,
  Matrix: (state) => state.matrix,
  UserIdState: (state) => state.userId,
  Show: (state) => state.show,
  EvaluationType: (state) => state.evaluationType,
  GetEvaluationSelected: (state) => state.evaluationSelected,
  IsGaugeChanging: (state) => state.isGaugeChanging,
  BalanceWeight: (state) => state.balance_weight,
  HasNullVolume: (state) => state.hasNullVolume,
  EntityHasNullVolume: (state) => state.entitiyHasNullVolume,
};

const actions = {

  ClearUserEvaluation({ state }) {
    state.userEvaluation[1] = []
    state.userEvaluation[2] = []
    state.userEvaluation[3] = []
  },

  async FetchMatrix({ commit, dispatch, state }, args) {
    try {
      const response = await KPI.CallBackEnd("get", "get-matrix", {
        params: { user_id: args, cycle: localStorage.getItem("cycle") },
      })

      if (!Object.keys(response.data).includes('matrix') || response.data.matrix == null || response.data.matrix.length <= 0) {
        commit("resetState");
        commit("storeUserId", args);
        return true;
      }

      let user_evaluation = response.data.user_evaluation;
      dispatch("ClearUserEvaluation")

      const qualiResponse = await KPI.CallBackEnd("get", "quali-control", {
        params: { cycle: localStorage.getItem("cycle") },
      })
      state.quali = qualiResponse.data

      state.userEvaluation[1].push(user_evaluation["user_integrated_eval"]);
      dispatch("GetQualiText", user_evaluation["user_integrated_eval"]).then((res) => state.userEvaluation[1].push(res));

      state.userEvaluation[2].push(user_evaluation["user_spec_eval"]);
      dispatch("GetQualiText", user_evaluation["user_spec_eval"]).then((res) => state.userEvaluation[2].push(res));

      state.userEvaluation[3].push(user_evaluation["user_weighted_eval"]);
      dispatch("GetQualiText", user_evaluation["user_weighted_eval"]).then((res) => state.userEvaluation[3].push(res));

      state.hasNullVolume = user_evaluation['has_null_volume']
      state.entitiyHasNullVolume = user_evaluation['entity_has_null_volume']

      await KPI.CallBackEnd("get", "get-entity-type-evaluation", {
        params: { cycle: localStorage.getItem("cycle") },
      }).then((res) => {
        state.evaluationType = res.data;

        for (let type in state.evaluationType) {
          if (state.evaluationType[type]["spec"] == true) {
            state.evaluationSelected = 2;
          } else {
            if (state.evaluationType[type]["integrated"] == true) {
              state.evaluationSelected = 1;
            } else {
              state.evaluationSelected = 3;
            }
          }
        }

        commit("storeMatrix", response.data);
        state.show = true;
      });

      state.balance_weight = response.data.balance_weight;

    } catch (erro) {
      Gems.Telegram(erro, "tele-not-allowed");
    }
  },

  async SaveMatrix({ state }, args) {
    const res = await KPI.CallBackEnd("post", "save-matrix", {
      user_id: state.userId,
      arrMatrix: state.matrix,
      permission: args[3],
      cycle: localStorage.getItem("cycle"),
      balance_weight: state.balance_weight,
    })

    if (res.data.error != true)
      Gems.Telegram("Gravado", "tele-success")
    else
      Gems.Telegram(res.data.message, "tele-info", "tele-ok")
  },

  async UserEvaluation({ state, dispatch }) {
    let evaluation_integrated = 0
    let evaluation_weighted = 0
    let evaluation_spec = 0
    let weight = 0
    let hasNullVolume = 0
    dispatch("ClearUserEvaluation")

    for (let matrix of state.matrix) {
      const res = await dispatch("CalcScopes", matrix.SCOPES)

      matrix.matrix_evaluation = res.matrixEval
      matrix.matrix_spec_eval = res.matrixEval
      matrix.matrix_integrated_eval = KPI.RoundValue((matrix.matrix_evaluation * (100 - res.entityWeight) + res.entityEval * res.entityWeight) / 100, 2)
      matrix.matrix_weighted_eval = KPI.RoundValue(matrix.matrix_evaluation * (res.entityEval / 100), 2)

      if (matrix.team_weight == null)
        matrix.team_weight = 0

      let toWeight = matrix.team_weight / 100
      evaluation_integrated += parseFloat(matrix.matrix_integrated_eval) * toWeight
      evaluation_weighted += parseFloat(matrix.matrix_weighted_eval) * toWeight
      evaluation_spec += parseFloat(matrix.matrix_spec_eval) * toWeight

      weight += toWeight

      hasNullVolume += res.scopeResultNull > 0 && matrix.team_weight > 0 ? 1 : 0
    }

    state.hasNullVolume = hasNullVolume > 0


    evaluation_integrated = evaluation_integrated ?? Gems.FormatDecimals(evaluation_integrated / weight, 2)
    state.userEvaluation[1].push(evaluation_integrated)
    const resIntegrated = await dispatch("GetQualiText", evaluation_integrated)
    state.userEvaluation[1].push(resIntegrated)

    evaluation_spec = evaluation_spec ?? KPI.RoundValue(evaluation_spec / weight, 2)
    state.userEvaluation[2].push(evaluation_spec)
    const resSpec = await dispatch("GetQualiText", evaluation_spec)
    state.userEvaluation[2].push(resSpec)

    evaluation_weighted = evaluation_weighted ?? KPI.RoundValue(evaluation_weighted / weight, 2)
    state.userEvaluation[3].push(evaluation_weighted)
    const resWeighted = await dispatch("GetQualiText", evaluation_weighted)
    state.userEvaluation[3].push(resWeighted)

  },

  GetQualiText({ state }, evaluation) {
    if (state.quali.length == 0)
      return

    let index = state.quali.findIndex(function (value) {
      return parseFloat(this) >= parseFloat(value.quali_min) && (parseFloat(this) < parseFloat(value.quali_max) || parseFloat(value.quali_max) == 100)
    }, evaluation)

    return state.quali[index]?.quali_desig
  },

  async CalcScopes({ dispatch }, scopes) {
    let teamEval = 0
    let entityWeight = 0
    let entityEval = 0
    let scopeResultNull = 0

    for (let scope of scopes) {
      scope.sco_evaluation = Number.isNaN(scope.sco_evaluation) ? 0 : scope.sco_evaluation

      if (scope.sco_id == 1) {
        entityWeight = parseFloat(scope.sco_weight)
        entityEval = scope.sco_evaluation
      } else {
        scope.real_weight = ((KPI.isNumeric(scope.sco_weight) ? parseFloat(scope.sco_weight) : 0) * (100 - entityWeight)) / 100

        if (scope.sco_id < 4) {
          await dispatch('CalcIndicators', scope.INDICATORS).then(res => {
            scope.sco_evaluation = Number.isNaN(res.sco_eval) ? 0 : KPI.RoundValue(res.sco_eval, 2)
            teamEval += (parseFloat(scope.sco_evaluation) * parseFloat(scope.sco_weight)) / 100

            if (scope.sco_weight > 0)
              dispatch("GetQualiText", scope.sco_evaluation).then((res) => scope.quali = res)
            else
              scope.quali = null

            scope.has_null_volume = res.indicatorResultNull > 0

            scopeResultNull += res.indicatorResultNull > 0 && scope.sco_weight > 0 ? 1 : 0

          })
        } else {
          if (scope.sco_evaluation && scope.sco_weight)
            teamEval += (parseFloat(scope.sco_evaluation) * parseFloat(scope.sco_weight)) / 100
        }
      }
    }

    return { entityEval, entityWeight, matrixEval: KPI.RoundValue(teamEval, 2), scopeResultNull }
  },

  CalcIndicators({ commit }, indicators) {
    let sco_eval = 0
    let indicatorResultNull = 0

    let indicatorsCopy = []
    let newWeight = 0

    indicators.forEach(async indicator => {
      if (indicator.sco_id < 4) {
        indicator.ind_weight = parseFloat(indicator.ind_weight)
        indicator.ind_vol = parseFloat(indicator.ind_vol)

        if (!KPI.isNumeric(indicator.ind_vol)) {
          indicatorResultNull++
          indicator.ind_evaluation = null
          indicator.ind_realization = null
        } else {
          commit("calculateEvaluation", { indicator, volume: indicator.ind_vol })
          newWeight += indicator.ind_weight
          indicatorsCopy.push(indicator)
        }

        if (indicator.ind_weight > 0) {
          for (let actualQuali of state.quali) {
            if (indicator.ind_evaluation >= parseInt(actualQuali.quali_min, 10) &&
              (indicator.ind_evaluation < parseInt(actualQuali.quali_max, 10) ||
                parseInt(actualQuali.quali_max, 10) == 100)
            ) {
              indicator.quali = actualQuali.quali_desig
              indicator.quali_color = actualQuali.quali_color
            }
          }
        }
      }
    })

    indicatorsCopy = indicatorsCopy.sort((a, b) => {
      if (parseFloat(a.ind_weight) > parseFloat(b.ind_weight))
        return 1

      if (parseFloat(a.ind_weight) == parseFloat(b.ind_weight))
        return 0

      if (parseFloat(a.ind_weight) < parseFloat(b.ind_weight))
        return -1
    })

    let lastIndicator = []
    let newWeightSum = 0
    if (indicatorsCopy.length > 1)
      lastIndicator = indicatorsCopy.pop()

    indicatorsCopy.forEach(indicator => {
      commit("calculateEvaluation", { indicator, volume: indicator.ind_vol })

      const indNewWeight = Number((indicator.ind_weight * 100 / newWeight).toString().split('.')[0])
      newWeightSum += indNewWeight
      sco_eval += parseFloat(KPI.isNumeric(indicator.ind_evaluation) ? indicator.ind_evaluation : 0) * (indNewWeight / 100)
    })

    if (lastIndicator)
      sco_eval += parseFloat(KPI.isNumeric(lastIndicator.ind_evaluation) ? lastIndicator.ind_evaluation : 0) * ((100 - newWeightSum) / 100)

    return { sco_eval: sco_eval > 100 ? 100 : sco_eval, indicatorResultNull }
  },

  ShowGauge(_, params) {
    if (params.type == "gauge") {
      params.gauge = !params.gauge;
      if (params.chat === true) {
        params.chat = !params.chat;
        params.commentIcon.classList.remove("tr-open");
      }
      if (params.gauge === true) {
        params.indicator.classList.add("indicator-opened");
      } else {
        params.indicator.classList.remove("indicator-opened");
      }
    } else {
      params.chat = !params.chat;
      if (params.gauge === true)
        params.gauge = !params.gauge;

      if (params.chat === true) {
        params.indicator.classList.add("indicator-opened");
        params.commentIcon.classList.remove("has_new_messages");
        params.commentIcon.classList.add("tr-open");
      } else {
        params.indicator.classList.remove("indicator-opened");
        params.commentIcon.classList.remove("tr-open");
        params.commentIcon.style.color = "#32323c";
      }
    }

    return [params.gauge, params.chat];
  },

  ValidateIndicator(_, indicator) {
    //Metrica linear inversa
    if (indicator.ind_met_id == 3 || indicator.ind_met_id == 4) {
      if (parseFloat(indicator.ind_mark) == parseFloat(indicator.ind_goal)) {
        Gems.Telegram("Objetivo e valor crítico não podem ser iguais", "tele-not-allowed")
        return false
      }

      if (parseFloat(indicator.ind_mark) < parseFloat(indicator.ind_goal)) {
        Gems.Telegram("Objetivo deve ser menor que o valor crítico", "tele-not-allowed")
        return false
      }
    }

    //Metrica linear direta
    if (indicator.ind_met_id == 1 || indicator.ind_met_id == 2) {
      if (parseFloat(indicator.ind_mark) > parseFloat(indicator.ind_goal)) {
        Gems.Telegram("Objetivo deve ser maior que o valor crítico", "tele-not-allowed")
        return false
      }
    }

    return true
  },

  StoreUserId({ commit }, userId) {
    commit("storeUserId", userId);
  },

  ResetMatrix({ commit }) {
    commit("resetState");
  },

  EvaluationSelected({ commit }, evaluation) {
    commit("storeEvaluationSelected", evaluation);
  },

  UpdateGauge({ commit }, status) {
    commit("updateGauge", status);
  },
};

const mutations = {
  storeMatrix: (state, matrix) => {
    state.userId = matrix["user_id"]
    state.matrix = matrix["matrix"]
  },

  storeShow: (state, show) => state.show = show,

  storeUserId: (state, userId) => state.userId = userId,

  storeEvaluationSelected: (state, evaluationSelected) => state.evaluationSelected = evaluationSelected,

  resetState: (state) => Object.assign(state, getDefaultState()),

  updateMatrixScope: (_, params) => params.scope.sco_weight = params.sco_weight,

  updateMatrixIndicator: (_, params) => Object.assign(params.indicator, params.new_indicator),

  updateGauge: (state, status) => state.isGaugeChanging = status,

  calculateEvaluation: async function (_, param) {
    param.indicator.ind_vol = param.volume
    let realization = KPI.RealizationPercentage(param.indicator)
    let evaluation = KPI.EvaluationPercentage(param.indicator)

    if (param.indicator.ind_met_id == 7) {
      param.indicator.tooltip = evaluation ? evaluation.tooltip : 'intervalo inexistente'
      realization = param.indicator.ind_vol
      evaluation = evaluation ? evaluation.evaluation : "0"
    }

    param.indicator.ind_realization = realization
    param.indicator.ind_evaluation = evaluation
  },

  calculateDirectMatchingEvaluation: function (_, param) {
    let metrics = param.indicator.METRICS
    param.indicator.METRICS = param.optionSelected

    const response = KPI.EvaluationPercentage(param.indicator)
    param.indicator.METRICS = metrics

    param.indicator.ind_realization = response.evaluation
    param.indicator.ind_evaluation = response.evaluation
    param.indicator.ind_vol = response.evaluation
    param.indicator.tooltip = response.tooltip
    param.indicator.title = response.option_value
  },

  setBalanceWeight: (state, balance) => {
    state.balance_weight = balance
    if (balance) {
      let matrixCount = 0
      for (let ind in state.matrix) {
        if (state.matrix[ind].team_weight != 0)
          matrixCount++
      }

      const weightBalanced = 100 / matrixCount
      let totalWeight = 0
      for (let ind in state.matrix) {
        if (ind == matrixCount && state.matrix[ind].team_weight != 0) {
          state.matrix[ind].team_weight = 100 - totalWeight
        } else if (state.matrix[ind].team_weight != 0) {
          totalWeight += Math.trunc(weightBalanced)
          state.matrix[ind].team_weight = Math.trunc(weightBalanced)
        }
      }
    }
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
