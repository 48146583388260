<template>
  <div>
    <div class="div-inline">
        <select class="select-indicator" @change="OpenGrid" v-model="indicatorSelectedIndex">
          <option v-for="(indicator, i) in indicatorsList" :value="i" :key="i">
            {{ indicator.designation }}
          </option>
        </select>

        <div class="tabs">
          <label for="interval" class="label-grow">
            <div id="interval-tab" class="interval-tab tab">
                <input type="radio" name="tabs" id="interval" class="cursor-pointer" :checked="directMatching == 0" @change="(ev)=>{if(ev.target.checked) OpenMetricTypeTab(0)}">
                <label>Intervalos</label>
            </div>
          </label>
          <label for="direct" class="label-grow">
            <div id="direct-tab" class="direct-tab tab">
                <input type="radio" name="tabs" id="direct" class="cursor-pointer" :checked="directMatching == 1"  @change="(ev)=>{if(ev.target.checked) OpenMetricTypeTab(1)}">
                <label>Correspondência direta</label>
            </div>
          </label>
        </div>
    </div>

    <div class="metric" v-show="indicatorSelectedIndex">
      <div class="tab-content">
        <table class="content-table">
          <thead class="content-header">
            <th v-if="directMatching" class="td-code">
              Código
            </th>

            <th ref="optionValue" class="th-option-value line-clamp" v-if="directMatching == 0" @mouseenter="Gems.TooltipIfNeeded($refs.optionValue)">
              Resultado (Min.)
            </th>

            <th ref="optionValue" class="th-option-value-direct-type line-clamp" v-else @mouseenter="Gems.TooltipIfNeeded($refs.optionValue)">
              Opções de resultado
            </th>

            <th ref="eval" class="th-evaluation line-clamp" @mouseenter="Gems.TooltipIfNeeded($refs.eval)">Avaliação (%)</th>

            <th class="th-legend" :class="directMatching == 1 ? 'th-tooltip-direct-type' : 'th-tooltip'">
              <div class="div-align-btn"> Legenda </div>
              <div>
                <button class="btn btn-action" @click="Save()">GRAVAR</button>
              </div>
            </th>
            
            <th class="th-minus">
              <button class="btn btn-add-line" @click="AddMetricTableElement()" ref="plus">+</button>
            </th>
          </thead>

          <tbody>
            <tr v-for="(row, index) in gridData" :key="index" class="content-row">
              <td v-if="directMatching" class="td-code">
                <input type="text" v-model="row.id" :placeholder="row.id ?? '--'" class="txt-left" maxlength="20" disabled/>
              </td>

              <td v-if="directMatching" class="td-option-value-direct-type">
                <input type="text" v-model="row.option_value" :placeholder="row.option_value" class="txt-left" maxlength="20" required/>
              </td>

              <td v-else class="td-option-value">
                <input type="text" v-model="row.option_value" :placeholder="row.option_value"  ref="interval" class="txt-left" maxlength="20" required/>
              </td>

              <td class="td-evaluation">
                <input type="text" v-model="row.evaluation" :placeholder="row.evaluation" class="txt-right" ref="evaluation" required/>
              </td>

              <td :class="directMatching == 1 ? 'td-tooltip-direct-type' : 'td-tooltip'">
                <input type="text" v-model="row.tooltip" :placeholder="row.tooltip" class="txt-right" ref="tooltipText" />
              </td>

              <td class="td-minus">
                <button class="btn btn-remove-line" ref="minus" @click="RemoveMetricTableElement(index)" >
                  -
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "manage-metrics",
  emits: ["force-reload"],
  data() {
    return {
      indicatorsList: [{ designation: "- Escolha o indicador -" }],
      indicatorSelected: Array,
      indicatorSelectedIndex: 0,
      gridData: [],
      deletedGridData: [],
      directMatching: 1,
      selectedUnit: "",
      Gems,
    };
  },
  methods: {
    async getIndicatorsTableMetric() {
      const response = await KPI.CallBackEnd("get", "get-operations", {
        params: {
          get: "get-table-metrics",
        },
      });

      this.indicatorsList = [{ designation: "- Escolha o indicador -" }]

      if (response.data.length > 0) 
        this.indicatorsList.push(...response.data)

      this.OpenGrid()
    },

    OpenGrid() {
      if (this.indicatorSelectedIndex == 0) 
        return;

      this.indicatorSelected = this.indicatorsList[this.indicatorSelectedIndex];
      this.deletedGridData = [];
      this.gridData = this.indicatorSelected.grid_data;
      this.selectedUnit = `(${this.indicatorSelected.unit})`;
      this.directMatching = this.gridData.length > 0 ? this.indicatorSelected.direct_matching : 1;
    },

    AddMetricTableElement() {
      if (this.gridData.length == 9) {
        Gems.Telegram("Limite de 10 escalas atingido", "tele-not-allowed")
        return
      }

      this.gridData.push({
        id: null,
        indicator_id: this.indicatorSelected.id,
        option_value: null,
        interval_max: null,
        evaluation: "",
        tooltip: "",
      });
    },

    RemoveMetricTableElement(index) {
      this.deletedGridData.push(...this.gridData.splice(index, 1))
    },

    async Save() {
      if (this.indicatorSelectedIndex == 0) 
        return;

      if (!KPI.RequiredFieldsValid()) 
        return;

      if (this.directMatching == 0) {
        if (this.gridData.filter((row)=> !this.CheckNumber(row.option_value)).length != 0)
          return;

        for (let i in this.gridData) {
          this.gridData[i]["interval_max"] = parseInt(i) + 1 == this.gridData.length ? 100 : this.gridData[parseInt(i) + 1]["option_value"]
        }
      }
        
      this.indicatorSelected.direct_matching = this.directMatching;
      this.indicatorSelected.grid_data = this.gridData;

      const response = await KPI.CallBackEnd("post", "save-metric-grid", {
        indicator: this.indicatorSelected,
        deletedGridData: this.deletedGridData,
      })

      if(!response.data.error)
        Gems.Telegram(response.data.message, "tele-success")
      else
        Gems.Telegram(response.data.message, "tele-not-allowed")

      this.getIndicatorsTableMetric()
    },

    CheckNumber(value) {
      if (!isNaN(value) && this.directMatching == 0)
          return true

      Gems.Telegram("Valor numérico", "tele-not-allowed")
      return false
    },

    OpenMetricTypeTab(directMatching) {
      this.directMatching = directMatching
    },
  },
  mounted() {
    this.getIndicatorsTableMetric();
  },
  updated() {
    if (this.$refs.minus)
      this.$refs.minus.map((elem) =>
        Gems.Tooltip(elem, "Remover", "tooltip")
      );

    if (this.$refs.plus)
      Gems.Tooltip(this.$refs.plus, "Nova linha", "tooltip");

    if (this.$refs.interval)
      this.$refs.interval.map((elem) =>
        Gems.Tooltip(elem, "Campo numérico", "tooltip")
      );

    if (this.$refs.metric)
      this.$refs.metric.map((elem) =>
        Gems.Tooltip(elem, "Campo numérico", "tooltip")
      );

    if (this.$refs.evaluation)
      this.$refs.evaluation.map((elem) =>
        Gems.Tooltip(elem, "Campo numérico", "tooltip")
      );
  },
};
</script>
  
<style scoped>

.metric {
  margin-top: 20px;
}

.tabs {
  display: flex;
  flex-grow: 1;
  width: 45%;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab input {
  margin: 0px 5px 0px 0px;
}

.none {
  display: none;
}

.active {
  opacity: 1 !important;
  background-color: white !important;
  border: none !important;
}

.interval-tab,
.direct-tab {
  flex: 1;
  height: 100%;
  text-align: center;
  font-weight: bold;
}

.interval-tab {
  border-right: none;
}
.direct-tab {
  border-left: none;
}

.content-table {
  display: block;
}

.content-table tbody {
  display: block;
}

.content-header,
.content-row {
  display: flex;
}

.btn-add-line,
.btn-remove-line {
  font-size: 1.5em !important;
}

.btn {
  width: 100%;
  height: 34px;
}

.btn-action {
  width: 8rem;
}

.intervals {
  display: flex;
  margin-top: 5px;
}

.td-option-value,
.th-option-value {
  width: 10%;
}

.th-option-value {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: default;
}

.td-option-value-direct-type,
.th-option-value-direct-type {
  width: 20%;
}

.td-evaluation,
.th-evaluation {
  width: 10%;
}

.td-tooltip,
.th-tooltip {
  width: 75%;
}

.td-tooltip-direct-type,
.th-tooltip-direct-type {
  width: 60%;
}

.td-minus,
.th-minus,
.td-code {
  width: 5%;
}

.txt-right,
.txt-left {
  width: -webkit-fill-available;
  height: 23px;
}

.indicator-ddl-label {
  font-weight: bold;
  margin-left: 15px;
}

.select-indicator {
  width: 85%;
  height: 35px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
}

.td-save {
  text-align: end;
}

.label-grow {
  flex: 1;
}

.div-inline {
  display: flex;
  width: -webkit-fill-available;
}

.th-legend {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.content-header {
    align-items: center;
    background-color: var(--special-app-color);
}

.div-align-btn {
  flex: 1;
}

.td-code input {
  border-color: transparent;
  background: var(--special-app-color);
  text-align: center;
}
</style>
