<template>
  <tr>
    <td class="td-scope">{{ objScope.sco_desig }}</td>
    <td class="td-weight">{{ objScope.sco_weight }}</td>
  </tr>
</template>

<script>
import { Gems } from '../gems';

export default {
  name: "TemplatesPreviewScope",
  props: {
    objScope: Object,
  },
  data(){
    return {
      Gems,
    }
  },
};
</script>

<style scoped>
.td-scope {
  width: 100%;
  text-align: left;
}
</style>
