<template>
  <table class="tbl-indicators">
    <thead class="thead-indicators" :class="`ind_color_${scopeId}`">
      <tr>
        <td class="td-id"></td>
        <td class="td-desig">Descrição</td>
        <td class="td-weight">Peso</td>
        <td class="td-quali" colspan="2">Avaliação</td>
        <td class="td-circle-color"></td>
      </tr>
    </thead>
    <tbody
      :class="[row_count++ % 2 == 0 ? 'greyRow' : 'special-app-color-row']"
      :key="indicator.ind_id"
      v-for="(indicator, row_count) in arrIndicators"
    >
      <MatrixCirvalueBlock :objIndicator="indicator" />
    </tbody>
  </table>
</template>

<script>
import MatrixCirvalueBlock from "./MatrixCirvalueBlock";

export default {
  name: "MatrixCirvalueList",
  props: {
    arrIndicators: Array,
    scopeId: [String, Number],
  },
  components: {
    MatrixCirvalueBlock,
  },
};
</script>

<style scoped>

.td-id {
  width: 4%;
}

.td-desig {
  width: 40%;
}

.td-weight {
  width: 20%;
}

.td-quali {
  width: 30%;
  text-align: center;
}

.td-circle-color {
  width: 5%;
  text-align: center;
}
</style>
