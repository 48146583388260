<template>
  <div>
    <div :key="scope.sco_id" v-for="scope in arrScopes">
      <TemplateScopeBlock :objScope="scope" :templateId="templateId" />
    </div>
  </div>
</template>

<script>
import TemplateScopeBlock from "./TemplateScopeBlock";

export default {
  name: "TemplateScopeList",
  props: {
    arrScopes: [Array, Object],
    templateId: [String, Number],
  },
  components: {
    TemplateScopeBlock,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  updated() {},
};
</script>

<style></style>
