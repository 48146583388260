<template>
  <div class="scope">
    <table :class="`scope-row sco_color_${scope.sco_id}`">
      <thead>
        <tr>
          <td class="tdIcon">
            <div v-for="(icon, index) in iconNames" :key="index">
              <div v-if="icon.id == scope.sco_id">
                <i :class="icon.name" class="icon fa-2x" aria-hidden="true"></i>
              </div>
            </div>
          </td>
          <td class="td-desig">
            <label for="show">{{ scope.sco_desig }}</label>
            <span class="real-weight-div">
              <p>Peso Real:</p>
              <p v-if="evalType == 1 && scope.sco_id == 1">
                {{ KPI.isNumeric(scope.sco_weight) ? Gems.FormatDecimals(scope.sco_weight, 2) : '' }}
              </p>
              <p v-if="evalType == 2 && scope.sco_id == 1">0</p>
              <p v-else-if="evalType == 3 && scope.sco_id == 1">-</p>
              <p v-else-if="evalType == 1 && scope.sco_id != 1">
                {{ KPI.isNumeric(scope.real_weight) ? Gems.FormatDecimals(scope.real_weight, 2) : '' }}
              </p>
              <p v-else-if="evalType != 1 && scope.sco_id != 1">
                {{ KPI.isNumeric(scope.sco_weight) ? Gems.FormatDecimals(scope.sco_weight, 2) : '' }}
              </p>
            </span>
            <p v-if="scope['INDICATORS'] == undefined"></p>
            <p v-else>Indicadores: {{ scope["INDICATORS"].length }}</p>
          </td>
          <td class="td-weight">
            <p v-if="permission == true">Peso padrão</p>
            <p v-else>Peso</p>
            <p v-if="cycle_finished == true || permission == false">
              {{ KPI.isNumeric(scope.sco_weight) ? Gems.FormatDecimals(scope.sco_weight, 2) : '' }}
            </p>
            <input type="text" ref="txtWeight" class="txtWeight" :value="Gems.FormatDecimals(scope.sco_weight ?? 0, 2)" @change="Update()" v-else />
          </td>
          <td class="td-evaluation" :class="{'without-result-row': scope.has_null_volume}">
            <div>
              <p>{{ Gems.FormatDecimals(scope.sco_evaluation, 2) }} %</p>
              <p v-if="scope.sco_weight > 0">{{ scope.has_null_volume ? '[Provisório]' : scope.quali }}</p>
            </div>
          </td>
          <td class="td-check">
            <i :class="show && scope.INDICATORS.length > 0 ? 'fas fa-sort-up' : 'fas fa-sort-down'"
              class="icon fa-2x cursor-pointer" aria-hidden="true" @click="show = !show" id="show-scope"></i>
          </td>
        </tr>
      </thead>
    </table>
    <div v-if="scope['INDICATORS'] == undefined"></div>
    <div v-else-if="scope['INDICATORS'].length > 0" v-show="show">
      <div v-if="scope.sco_id < 4">
        <MatrixIndicatorList
          :scopeID="scope.sco_id"
          :userIndicators="scope['INDICATORS']"
          :permission="permission"
          :cycleFinished="cycle_finished"
          :teamId="teamId"
        />
      </div>
      <div v-else>
        <MatrixCirvalueList
          :arrIndicators="scope['INDICATORS']"
          :scopeId="objScope.sco_id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MatrixIndicatorList from "./MatrixIndicatorList";
import MatrixCirvalueList from "./MatrixCirvalueList";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { KPI } from "@/kpi";
import { Gems } from "../gems";

export default {
  name: "MatrixScopeBlock",
  props: {
    objScope: Object,
    cycleScope: Boolean,
    userIndicators: Array,
    company: Boolean,
    permission: Boolean,
    teamId: [String, Number],
    evalType: [String, Number],
  },
  components: {
    MatrixIndicatorList,
    MatrixCirvalueList,
  },
  methods: {
    ...mapActions([
      "FetchCycleIndicator",
      "UserEvaluation",
    ]),

    ...mapMutations(['updateMatrixScope']),

    async Update() {
      this.$refs.txtWeight.value = await KPI.FormatWeight(this.$refs.txtWeight.value)

      this.updateMatrixScope({scope: this.objScope, sco_weight: this.$refs.txtWeight.value})

      this.UserEvaluation()
    },
  },
  computed: {
    ...mapGetters([
      "arrTemplate",
      "GetEvaluationSelected",
      "CompanyWeight",
    ]),
  },
  data() {
    return {
      indicators: [],
      scope: [],
      show: true,
      iconNames: {
        0: { id: 1, name: "fas fa-industry" },
        1: { id: 2, name: "fas fa-users" },
        2: { id: 3, name: "fas fa-male" },
        3: { id: 4, name: "fas fa-chart-pie" },
        4: { id: 5, name: "fas fa-chart-area" },
      },
      cycle_finished: String,
      Gems,
      KPI,
    };
  },
  created() {
    if (this.cycleScope != true) {
      if (this.objScope !== null) {
        this.scope = this.objScope;
        this.indicators = this.objScope["INDICATORS"];
      }
    }
    // this.FetchCycleIndicator(localStorage.getItem("cycle"))
    this.cycle_finished = JSON.parse(localStorage.getItem("cycle_finished"));
  },
};
</script>

<style scoped>

.scope-row {
  border-spacing: 0px;
  color: var(--light-gray-color);
}

.td-check {
  width: 0.5%;
  padding: 10px;
}
.tdIcon {
  width: 5%;
  text-align: center;
}
.td-desig {
  width: 19%;
  padding: 5px;
}

.td-desig label {
  font-size: 1.2em;
  font-weight: bold;
}

.td-evaluation {
  padding: 15px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
}

.td-weight {
  width: 10%;
}

.txtWeight {
  width: 32px;
  height: 10px;
  text-align: center;
  border-radius: 0px !important;
  border: none;
  margin: 7px 3px 6px 3px;
  font-size: 1em;
}

.real-weight-div {
  display: flex !important;
}
.real-weight-div p:first-child {
  margin-right: 3px;
}

.fa-sort-up {
  transform: translateY(3px);
}

.fa-sort-down {
  transform: translateY(-6px);
}

@media only screen and (max-width: 1400px) {
  .scope-row {
    padding-left: 15px;
  }
  
  .td-check {
    width: 0.25%;
  }

  .td-desig {
    width: 16%;
    padding: 5px 5px 5px 20px;
  }

  .td-evaluation {
    padding: 12px;
  }

  .td-weight {
    padding-left: 15px;
  }

  .txtWeight {
    width: 24px;
  }
}
</style>
