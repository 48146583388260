<template>
  <table class="tbl-indicators">
    <thead :class="`thead-indicators ind_color_${scopeId}`">
      <tr>
        <td class="td-id"></td>
        <td class="td-desig">Ciclo</td>
        <td class="td-date">Data Inicial</td>
        <td class="td-date">Data Final</td>
        <td class="td-active">Ativo</td>
        <td class="td-select" v-if="template != true">Selecionar</td>
      </tr>
    </thead>

    <tbody
      :class="[row_count++ % 2 == 0 ? 'greyRow' : 'special-app-color-row']"
      :key="indicator.ind_id"
      v-for="(indicator, row_count) in arrIndicators"
    >
      <TemplateCirvalueBlock
        :objIndicator="indicator"
        :picked="picked"
        :select="select"
        :template="template"
        @update="ChangePicked($event)"
      />
    </tbody>
  </table>
</template>

<script>
import TemplateCirvalueBlock from "./TemplateCirvalueBlock";

export default {
  name: "TemplateCirvalueList",
  props: {
    arrIndicators: [Array, Object],
    scopeId: Number,
    select: Boolean,
    template: Boolean,
  },
  components: {
    TemplateCirvalueBlock,
  },
  data() {
    return {
      picked: "",
    };
  },
  methods: {
    ChangePicked(e) {
      for (let indicator in this.arrIndicators) {
        if (this.arrIndicators[indicator]["cycle_id"] == e) {
          Object.assign(this.arrIndicators[indicator], { selected: true });
        } else {
          Object.assign(this.arrIndicators[indicator], { selected: false });
        }
      }
      this.picked = e;
    },
  },
  mounted() {
    for (let indicator in this.arrIndicators) {
      if (this.arrIndicators[indicator]["selected"] == true) {
        this.picked = this.arrIndicators[indicator]["cycle_id"];
      }
    }
  },
};
</script>

<style scoped>

.td-id {
  width: 1%;
  padding-right: 2px;
}

.td-desig {
  width: 30%;
}

.td-date {
  width: 25%;
  text-align: center;
}

.td-active,
.td-select {
  width: 10%;
  text-align: center;
}
</style>
