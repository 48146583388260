<template>
    <div class="page-container">
        <div class="page-title">
            <h2>RELATÓRIO DE SISTEMA</h2>
        </div>
    
        <div class="div-page-header-container">
    
            <div class="div-cycles">
                <div class="div-row">
                    <span>CICLO COMP: </span>
                    <span class="third-app-span">{{CompCycleSelected ? CompCycleSelected.cycle : "--" }}</span>
                </div>
    
                <div class="div-row">
                    <span>CICLO 360: </span>
                    <span class="third-app-span">{{_360CycleSelected ? _360CycleSelected.cycle : "--"}}</span>
                </div>
            </div>
    
            <div class="div-ddl">
                <div>
                    <span>Ordenar por:</span>
                    <select id="order-ddl" class="cursor-pointer" @change="OrderBy($event.target.value)" @mouseenter="Gems.Tooltip($event.target, `${$event.target.selectedOptions[0].text}`, 'tooltip')">
                        <option value="name-asc">Nome &#8593;</option>
                        <option value="name-desc" desc>Nome &#8595;</option>
                        <option value="teams_without_matrix-asc">Equipas Sem Matriz &#8593;</option>
                        <option value="teams_without_matrix-desc">Equipas Sem Matriz &#8595;</option>
                        <option value="entity_indicators_null-asc">Indicadores [Empresa] Sem Resultados &#8593;
                        </option>
                        <option value="entity_indicators_null-desc">Indicadores [Empresa] Sem Resultados &#8595;
                        </option>
                        <option value="teams_indicators_null-asc">Indicadores [Equipa] Sem Resultados &#8593;
                        </option>
                        <option value="teams_indicators_null-desc">Indicadores [Equipa] Sem Resultados &#8595;
                        </option>
                        <option value="individual_indicators_null-asc">Indicadores [Indivíduo] Sem Resultados &#8593;
                        </option>
                        <option value="individual_indicators_null-desc">Indicadores [Indivíduo] Sem Resultados &#8595;
                        </option>
                    </select>
                </div>
    
                <div>
                    <span>Filtrar por equipa:</span>
                    <select id="teams-ddl" class="cursor-pointer" @change="GetAppReport($event.target.value)">
                        <option value="">Todas</option>
                        <option :value="team.id" v-for="team in arrTeams" :key="team.id">
                            {{ team.designation }}
                        </option>
                    </select>
                </div>
            </div>
    
        </div>
    
        <div>
            <div class="header" id="header">
                <div class="div-user-photo-header"></div>
                <div class="width-20">Nome</div>
                <div class="div-team">Equipa</div>
                <div class="width-20">Equipas Sem Matriz</div>
                <div class="width-29">
                    <span>Indicadores Sem Resultados</span>
                    <div class="indicators-null">
                        <span>Empresa</span>
                        <span>Equipa</span>
                        <span>Indivíduo</span>
                    </div>
                </div>
            </div>
            <div id="users-list">
                <div class="user cursor-pointer" :class="{'user-alt': i % 2}" v-for="(user, i) in arrUsers" :key="user.user_id">
                    <div class="div-user-photo">
                        <img class="div-user-photo" :src="user.photo ?? defaultImage" @error="$event.target.src = defaultImage"></img>
                    </div>
    
                    <div class="width-20 line-clamp">{{ user.name }}</div>
    
                    <div class="div-team line-clamp" @mouseenter="SetTeamHover(user, $event.target)">{{ SetTeamField(user) }}</div>
    
                    <div class="width-20">{{ user.teams_without_matrix }}</div>
                    
                    <div class="width-29 indicators-null">
                        <span>{{ user.entity_indicators_null }}</span>
                        <span>{{ user.teams_indicators_null }}</span>
                        <span>{{ user.individual_indicators_null }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { KPI } from '@/kpi'
import { Gems } from '@/gems'
import { mapGetters, mapActions } from 'vuex';
  
export default {
    name: "app-report",
    emits: ["force-reload"],
    data() {
      return {
        arrTeams: Array,
        arrUsers: Array,
        defaultImage: require('../images/default.png'),
        Gems
      };
    },
    methods: {

        ...mapActions(["GetThirdPartyApplicationChoice"]),

        async GetAppReport(team_id = null) {
            const response = await KPI.CallBackEnd("get", "get-app-report", {
                params: {
                    cycle_id: localStorage.getItem('cycle'),
                    team_id
                },
            })

            if(response.error)
                Gems.Telegram('Ocorreu um erro!', 'tele-not-allowed')

            this.arrTeams = response.data?.teams
            this.arrUsers = response.data?.users
        },
        
        OrderBy(value) {
            const desc = value.substring(value.search('-')) == '-desc'
            value = value.substring(0, value.search('-'))

            this.arrUsers.sort((a, b) => {
                if (value == 'name' || value == 'team')
                    return desc ? b[`${value}`].localeCompare(a[`${value}`]) : a[`${value}`].localeCompare(b[`${value}`])
                else
                    return desc ? a[`${value}`] - b[`${value}`] : b[`${value}`] - a[`${value}`]
            })
        },

        SetTeamField(user) {
            const arrTeams = user.teams.map(value => value["team"])
            return  arrTeams.length > 1 ? `${arrTeams.length} equipas` : arrTeams.length == 1 ? arrTeams[0] : "[sem equipa]"
        },

        SetTeamHover(user, teamField) {
            const arrTeams = user.teams.map(value => value["team"])
            const teamsList = arrTeams.length > 0 ? (arrTeams.slice().toString()).replaceAll(",", "\n") : '[sem equipa]'
 
            if (arrTeams.length > 1)
                Gems.Tooltip(teamField, teamsList, 'tooltip')
            else
                teamField.onmouseover = (e) => Gems.TooltipIfNeeded(e.target)

        },
        
        HandleScroll() {
            const divHeader = document.getElementById("header")
            const size = 165

            if (divHeader) {
                var sticky = divHeader.offsetTop

                if (document.body.scrollTop > sticky) 
                    divHeader.classList.add("sticky")
                    
                if (document.body.scrollTop < size) 
                    divHeader.classList.remove("sticky")
            }
        }
    },
    computed: {
        ...mapGetters([
            "CompCycleSelected",
            "_360CycleSelected",
        ]),
    },
    async mounted() {
        window.addEventListener("scroll", this.HandleScroll, true);

        this.GetAppReport()
        await this.GetThirdPartyApplicationChoice();
    }
};
</script>
  
<style scoped>

    .page-container {
        padding: 1%;
    }

    .third-app-span {
        font-weight: bold;
        margin-left: 5px;
    }

    .page-title {
        text-align: left;
    }

    .div-page-header-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .div-ddl {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 5px;
    }

    .div-ddl div {
        display: flex;
        gap: 2px;
        align-items: center;
    }

    .div-ddl span {
        display: inline-flex;
    }

    .div-ddl select {
        height: 31px;
        padding: 5px;
        width: 175px;
    }

    .div-cycles {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .div-row {
        display: flex;
        justify-content: flex-start;
        gap: 2px;
        align-items: end;
    }

    .div-row span:nth-child(2) {
        font-weight: 600;
    }

    .user,
    .header {
        display: flex;
        gap: 5px;
        font-size: .9em;
        align-items: center;
        padding: 2px 0;
        font-weight: 300;
        text-align: center;
    }

    .user:hover {
        background-color: var(--medium-app-color);
    }

    .user-alt {
        background-color: var(--special-app-color);
    }

    .header {
        background-color: var(--default-app-color);
        color: white;
        font-size: .9em;
        border-radius: 3px;
    }

    .div-team {
        width: 25% !important;
    }

    .div-user-photo {
        width: 35px !important;
        height: 35px !important;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        padding: 0;
        vertical-align: bottom;
        object-fit: cover;
    }

    .div-user-photo-header {
        width: 35px !important;
    }

    .header div {
        word-break: break-word;
        cursor: default;
    }

    .width-20 {
        width: 20%;
    }

    .width-29 {
        width: 29%;
    }

    .indicators-null {
        display: grid;
        gap: 1%;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .sticky {
        position: fixed;
        top: 0;
        z-index: 2;
        width: 1021px;
    }

    .sticky+#users-list {
        padding-top: 36px;
    }
</style>
  