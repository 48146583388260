import { Gems } from "../../gems"
import { KPI } from "../../kpi"

const state = {
  cycle: [],
  reloadMenu: false,
};

const getters = {
  ArrCycle: (state) => state.cycle,
  ReloadMenuState: (state) => state.reloadMenu,
};

const actions = {
  async GetMenuInfo({ commit }, cycle_id) {
    const response = await KPI.CallBackEnd("get", "get-operations", {
      params: {
        get: "menu-info",
        id: cycle_id
      },
    })


    if (response.data.length == 0)
      window.parent.location.href = `cycles`

    commit("storeCycle", response.data)
  },

  async SaveCycleDefinitions({ state }) {
    const response = await KPI.CallBackEnd("post", "save-cycle-definitions", {
      params: { cycle: state.cycle },
    })

    if (response.data.error)
      Gems.Telegram("Erro!", "tele-not-allowed")
  },

  ReloadMenu({ state }) {
    state.reloadMenu = !state.reloadMenu;
  },
};

const mutations = {
  storeCycle: (state, cycle) => (state.cycle = cycle),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
