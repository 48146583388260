<template>
  <div class="div-content">
    <div class="lbl">
      Pedido de ajuda
      <i id="i-help-icon" class="fa fa-info-circle help-icon"></i>
    </div>
    <table class="task-table">
      <tr>
        <td style="text-align: right; width: 30%">
          <div class="div-label label">TIPO:</div>
        </td>
        <td style="text-align: left">
          <select name="ddl-types" id="ddl-types" class="dll-types" required></select>
        </td>
      </tr>
      <tr>
        <td style="text-align: right">
          <div class="div-label label">ASSUNTO:</div>
        </td>
        <td style="text-align: left">
          <input id="txt-subject" name="txt-subject" type="text" class="txt-input" required />
        </td>
      </tr>
      <tr>
        <td style="text-align: right; width: 20%; vertical-align: top">
          <div class="div-label label desc">DESCRIÇÃO:</div>
        </td>
        <td style="text-align: left" colspan="2">
          <textarea id="txt-description" name="txt-description" type="text" class="txt-area" required></textarea>
        </td>
      </tr>
      <tr>
        <td></td>
        <td class="btns" colspan="2">
          <button id="btn-send" type="button" class="send btn-action" @click="Send()">
            ENVIAR
          </button>
        </td>
      </tr>
      <tr>
        <td></td>
        <td class="btns" colspan="2">
          <input type="file" id="attach-document" class="hidden" multiple />
          <button id="btn-attach-document" @click="AttachDoc()">
            ANEXAR FICHEIRO
          </button>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colspan="2">
          <div id="doc-list"></div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "report-errors",
  data() {
    return {
      name: null,
      email: null,
    };
  },
  methods: {
    async GetUserName() {
      const response = await KPI.CallBackEnd("get", "get-user")

      this.user = response.data[0][0]["professional_name"]
      this.email = response.data[0][0]["user_email"]
    },

    FillFileList(input) {
      const docList = document.getElementById("doc-list");

      Array.from(input.files).forEach((file, i) => {
        const div = document.createElement("div");
        const span = document.createElement("span");
        const removeIcon = document.createElement("i");

        removeIcon.className = "fas fa-times remove-file cursor-pointer";
        div.className = "div-file";
        span.className = "line-clamp break-word file-name";

        span.innerHTML = file.name;

        span.onmouseover = function () {
          Gems.TooltipIfNeeded(this);
        };

        removeIcon.onclick = () => {
          const newFileList = new DataTransfer();
          const actualFileList = document.getElementById("attach-document");
          let { files } = actualFileList;

          Array.from(files).forEach((file, ind) => {
            if (ind != i) newFileList.items.add(file);
          });

          actualFileList.files = newFileList.files;

          this.FillFileList(actualFileList);
        };

        Gems.Tooltip(removeIcon, "Remover", "tooltip");

        div.appendChild(span);
        div.appendChild(removeIcon);

        docList.appendChild(div);
      });
    },

    AttachDoc() {
      document.getElementById("attach-document").click();
    },

    Send() {
      if (KPI.RequiredFieldsValid()) {
        Gems.Telegram("Comunicar este problema?", "tele-info", "", "").then(
          async () => {
            const btn = document.getElementById("btn-send");
            btn.innerText = "Aguarde...";
            btn.setAttribute("disabled", true);
            btn.style.cursor = "default";

            const subject = document.getElementById("txt-subject");
            const description = document.getElementById("txt-description");
            const attachDocumentElement = document.getElementById("attach-document")
            const formData = new FormData();

            formData.append(
              "input",
              JSON.stringify({
                application: "KPI",
                subject: subject.value,
                description: description.value,
                user_name: this.user,
                user_email: this.email,
              })
            );

            if (attachDocumentElement.files.length > 0)
              Array.from(attachDocumentElement.files).forEach((file) =>
                formData.append("documents[]", file)
              );

            try {
              const response = await Gems.CallWS({
                method: "post",
                app: "intime",
                action: "send-task",
                body: formData,
              })
              if (response.status != 200) {
                Gems.Telegram("Não foi possível comunicar o problema", "tele-not-allowed")
                return
              }

              Gems.Telegram("Recebemos a sua solicitação. Responderemos o mais brevemente possível", "tele-info", "")
              document.getElementById("ddl-types").value = 1
              subject.value = ""
              description.value = ""
              document.getElementById("doc-list").innerHTML = ""
              attachDocumentElement.value = null

              return
            } finally {
              btn.innerText = "ENVIAR"
              btn.removeAttribute("disabled")
              btn.style.cursor = "pointer"
            }
          },
          () => { }
        );
      }
    },
  },
  mounted() {
    document.getElementById("ddl-types").innerHTML = "";
    let options = [
      { 0: 1, 1: "Selecione o tipo do problema" },
      { 0: 2, 1: "Dificuldade técnica" },
      { 0: 3, 1: "Dúvida na utilização/navegação" },
    ];
    KPI.FillDDL(options, "ddl-types");
    this.GetUserName();

    Gems.Tooltip(
      document.getElementById("i-help-icon"),
      'Recomendamos que leia a informação disponibilizada na documentação de "Ajuda"',
      "tooltip"
    );

    document
      .getElementById("attach-document")
      .addEventListener("change", (ev) => this.FillFileList(ev.target));
  },
};
</script>

<style>
.div-file {
  display: flex;
  justify-content: space-between;
  text-align: left;
  cursor: default;
  align-items: center;
  padding: 2px 0px;
}

.break-word {
  word-break: break-all;
}

.remove-file {
  color: orangered;
}

.file-name {
  font-size: 0.8em;
  font-weight: 600;
}
</style>

<style scoped>
.div-content {
  text-align: center;
  width: 100%;
  margin-top: 50px;
}

.label {
  font-size: 10pt;
  padding-right: 10px;
  display: inline-block;
}

.lbl {
  font-size: 20pt;
  font-weight: bold;
  margin: 0 0 30px 60px;
  text-align: left;
}

.dll-types {
  width: 100%;
  border: 1px solid var(--medium-gray-color);
  padding: 10px;
}

.txt-area {
  width: 96%;
  resize: vertical;
  height: 155px;
  border: 1px solid var(--medium-gray-color);
  padding: 10px;
}

.txt-input {
  width: 96%;
  padding: 10px;
}

.btns {
  text-align: left;
  margin-bottom: 5px;
}

.btns button {
  width: 100%;
}

.task-table {
  width: 80%;
  border-spacing: 5px;
}

.send {
  margin-top: -2px;
}

.desc {
  margin-top: 9px;
}

.help-icon {
  font-size: .7em;
}
</style>
