<template>
  <div v-show="show" class="matrix-container">
    <div :key="scope" v-for="scope in arrScopes['SCOPES']">
      <div v-if="typeof scope === 'object' && scope !== null">
        <MatrixScopeBlock
          :objScope="scope"
          :permission="permission"
          :teamId="teamId"
          :evalType="evalType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MatrixScopeBlock from "./MatrixScopeBlock"

export default {
  name: "MatrixScopeList",
  props: {
    arrScopes: Object,
    teamId: Number,
    show: Boolean,
    permission: Boolean,
    evalType: [String, Number],
  },
  components: {
    MatrixScopeBlock,
  },
};
</script>

<style scoped>

</style>
