import { KPI } from "../../kpi";
import { reactive } from "vue";
import { Gems } from "../../gems";

const state = reactive({
  teams: [],
  teamId: Number,
});

const getters = {
  arrTeamsIndicators: (state) => state.teams,

  TeamId: (state) => state.teamId
};

const actions = {
  async FetchTeams({ commit }, team_id) {
    let openTeamArr = []

    const response = await KPI.CallBackEnd("get", "get-team-indicators", {
      params: { team: team_id, cycle_id: localStorage.getItem("cycle") },
    })

    if (response.data.length == 0)
      openTeamArr.push({ INDICATORS: [] })
    else
      openTeamArr.push({ INDICATORS: response.data })

    commit("storeTeam", openTeamArr)
    commit("storeTeamId", team_id)
  },

  ValidateTeamWeights({ state }) {
    let indicatorSum = 0

    state.teams[0].INDICATORS.forEach(indicator => indicatorSum += parseFloat(indicator.ind_weight))

    if (indicatorSum != 100 && state.teams[0].INDICATORS.length > 0) {
      Gems.Telegram(`A soma dos pesos dos indicadores precisa ser igual a 100`, "tele-not-allowed")
      return false
    }

    return true
  },

  async SaveTeam({ dispatch }, args) {

    if (!await dispatch('ValidateTeamWeights'))
      return false

    const response = await KPI.CallBackEnd("post", "update-team-indicators",
      { arrTeam: state.teams, cycle: localStorage.getItem("cycle"), team_id: state.teamId, team_name: args }
    )

    if (response.error) {
      Gems.Telegram("Ocorreu um erro", "tele-not-allowed")
      return false
    }

    Gems.Telegram("Operação realizada com sucesso", "tele-success")
    dispatch("FetchTeams", state.teamId)
  },
};

const mutations = {
  storeTeam: (state, teams) => (state.teams = teams),

  storeTeamId: (state, teamId) => (state.teamId = teamId),

  storeMetrics: (state, metrics) => (state.metrics = metrics),

  updateIndicatorTeam: (_, params) => Object.assign(params.indicator, params.new_indicator),

  addIndicatorTeam: (state, indicator) => state.teams[0]["INDICATORS"].push(indicator),

  removeIndicatorTeam: (state, indicator) => {
    const indicator_ind = state.teams[0].INDICATORS.findIndex(ind => ind.ind_id == indicator.ind_id)

    state.teams[0].INDICATORS.splice(indicator_ind, 1)
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
