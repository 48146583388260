<template>
  <div @drop.stop="DropTemplate($event)" @dragover.prevent @dragenter.prevent>
    <p class="function-title">{{ objFunc.func_desc }}</p>
    <p class="template-title" v-if="objFunc.template_count == 1">{{ objFunc.template_desig }}</p>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "FunctionsBlock",
  emits: ["update-teams"],
  props: {
    objFunc: Object,
    volume: Boolean,
    card: String,
    func: Boolean,
  },
  methods: {
    async SetTemplate(template) {
      await KPI.CallBackEnd("post", "set-template-functions", {
        template: template.temp_id,
        volume: this.volume,
        overFunc: this.func,
        func: this.objFunc,
        cycle_id: localStorage.getItem("cycle"),
      }).then((res) => {
        if (res.data?.error) {
          if (res.data.message)
            Gems.Telegram(res.data.message, "tele-not-allowed")
          else
            Gems.Telegram("Erro ao alterar o template, tente novamente", "tele-not-allowed")
          return
        }

        Object.assign(this.objFunc, res.data.function)

        this.$emit("update-teams")

        if (res.data.message)
          Gems.Telegram(res.data.message, "tele-success")
        else
          Gems.Telegram("Operação realizada com sucesso!", "tele-success")
      });
    },

    DropTemplate(event) {
      if (this.card === "Users")
        Gems.Telegram("Não é possível adicionar utilizadores as funções", "tele-not-allowed")
      else
        this.SetTemplate(JSON.parse(event.dataTransfer.getData("template")))
    },
  },
};
</script>

<style scoped>
.function-title,
.template-title {
  font-weight: 600;
  cursor: default;
}

.function-title {
  font-size: 1.3em;
}

.template-title {
  font-size: 1em;
}
</style>
