<template>
  <div class="div-indicator-radio">
    <input
      type="radio"
      @click="$emit('ChangeRadio', RadioEval(true))"
      :checked="KPI.isNumeric(objIndicator.ind_vol) ? objIndicator.ind_vol == RadioValue(true) : false"
      :disabled="disabled"
    />Sim
    <input
      type="radio"
      @click="$emit('ChangeRadio', RadioEval(false))"
      :checked="KPI.isNumeric(objIndicator.ind_vol) ? objIndicator.ind_vol == RadioValue(false) : false"
      :disabled="disabled"
    />Não
  </div>
</template>

<script>
import { KPI } from '../kpi';

export default {
    name: "IndicatorBooleanVolume",
    emits: ["ChangeRadio"],
    data() {
      return {
        KPI,
      }
    },
    props: {
        objIndicator: Object,
        disabled: Boolean
    },
    methods: {
      IsAffirmativeMetric() {
        return this.objIndicator.ind_met_id == 5
      },

      RadioValue(isYesOpt) {
        if(this.IsAffirmativeMetric())
          return isYesOpt ? 1 : 0

        return isYesOpt ? 0 : 1
      },

      /* This returns 3 values: 0, 1 or NULL
         In the case of positive binary metric: the 100% eval is 1 and 0% is 0
         In the case of negative binary metric: the 100% eval is 0 and 0% is 1
         In both cases: NULL is provisory */
      RadioEval(isYesOpt) {
        const valToCheck = this.RadioValue(isYesOpt)
        return this.objIndicator.ind_vol == valToCheck ? null : valToCheck
      },
    }
}
</script>

<style scoped>
  .div-indicator-radio {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    margin-top: 0;
    cursor: pointer;
  }
</style>