<template>
  <tr class="indicator-row">
    <td class="td-id">{{ objIndicator.ind_id }}</td>
    <td class="td-desig">{{ objIndicator.ind_desc }}</td>
    <td class="td-weight">{{ Gems.FormatDecimals(objIndicator.ind_weight, 2) }}</td>
    <td class="td-eval" ref="eval">{{ Gems.FormatDecimals(objIndicator.ind_evaluation, 2) }}%</td>
    <td class="td-eval">{{ objIndicator.ind_rating }}</td>
    <td>
      <div class="rating-color" :class="`${objIndicator.quali_color}-quali-color-bg`"></div>
    </td>
  </tr>
</template>

<script>
import { Gems } from '../gems';

export default {
  name: "MatrixCirvalueBlock",
  props: {
    objIndicator: {},
  },
  data() {
    return {
      Gems,
    };
  },
  mounted() {
    if (this.$refs.eval && this.objIndicator.ind_evaluation) 
      Gems.Tooltip( this.$refs.eval, this.objIndicator.ind_evaluation + "%", "tooltip")
  }
};
</script>

<style scoped>

.td-eval {
  text-align: center;
  cursor: default;
  width: 10%;
}

.rating-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 auto;
}

</style>
