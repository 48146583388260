<template>
  <tr v-if="cycle == true || team == true" :style="objIndicator.visible">
    <td class="tdInternal">{{ objIndicator.ind_internal }}</td>
    <td class="tdDesignation" ref="desc">{{ objIndicator.ind_desig }}</td>
    <td class="tdMetric">{{ objIndicator.ind_met }}</td>
    <td class="td-unit">{{ objIndicator.ind_unit }}</td>
    <td class="td-include">
      <input
        :class="cycle_finished === 'true' ? 'is-disabled' : ''"
        v-if="cycle == true"
        type="checkbox"
        ref="chkTeam"
        :checked="objIndicator.company !== null"
        @change="ChangeIndicator(1, objIndicator, this.$refs.chkTeam.checked)"
      />
      <input
        :class="cycle_finished === 'true' ? 'is-disabled' : ''"
        v-else
        type="checkbox"
        ref="chkTeam"
        :checked="objIndicator.company !== null"
        @change="ChangeIndicator(2, objIndicator, this.$refs.chkTeam.checked)"
      />
    </td>
  </tr>
  <tr :style="objIndicator.visible" v-else>
    <td class="tdInternal">{{ objIndicator.ind_internal }}</td>
    <td class="tdDesignation" ref="desc">{{ objIndicator.ind_desig }}</td>
    <td class="tdMetric">{{ objIndicator.ind_met }}</td>
    <td class="td-unit">{{ objIndicator.ind_unit }}</td>
    <td class="td-include">
      <input
        :class="cycle_finished === 'true' ? 'is-disabled' : ''"
        type="checkbox"
        ref="chkIndividual"
        :checked="objIndicator.individual !== null"
        @change="ChangeIndicator(3, objIndicator, this.$refs.chkIndividual.checked)"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Gems } from "../gems";
import { KPI } from "../kpi";

export default {
  name: "SearchIndicatorBlock",
  props: {
    objScopes: Object,
    objIndicator: Object,
    cycle: Boolean,
    team: Boolean,
  },
  data() {
    return {
      cycle_finished: String,
    };
  },
  methods: {

    ...mapMutations(['addIndicator', 'removeIndicator', 'addIndicatorCycle', 'removeIndicatorCycle', 'addIndicatorTeam', 'removeIndicatorTeam']),

    CreateIndicator(indicator, scope_id) {
      if(!this.cycle && !this.team)
        indicator.temp_id = this.arrTemplate["TEMPLATE"][0]["temp_id"]

      indicator.sco_id = scope_id
      indicator.above100 = KPI.ABOVE100.NONE
      indicator.ind_realization = null
      indicator.ind_volume = null
      indicator.ind_evaluation = null
      indicator.ind_goal = null
      indicator.ind_mark = null
      indicator.ind_vol = null

      return indicator
    },

    ChangeIndicator(scope_id, indicator, checked) {
      indicator = this.CreateIndicator(indicator, scope_id)

      switch(scope_id) {
        case 1:
          if(checked)
            this.addIndicatorCycle(indicator)
          else 
            this.removeIndicatorCycle(indicator)
          break
        case 2:
          if (checked)
            this.addIndicatorTeam(indicator)
          else
            this.removeIndicatorTeam(indicator)
          break
        case 3:
          if (checked)
            this.addIndicator(indicator)
          else 
            this.removeIndicator(indicator)
          break
      }
    },
  },

  computed: mapGetters(["arrTemplate"]),

  mounted() {
    Gems.Tooltip(this.$refs.desc, this.objIndicator["ind_desc"], "tooltip");
  },
  updated() {
    this.cycle_finished = localStorage.getItem("cycle_finished");
  },
};
</script>

<style>
.tdDesignation {
  cursor: default;
  text-align: start;
}

.is-disabled {
  pointer-events: none;
}

</style>
