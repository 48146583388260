<template>
  <table class="tbl-indicators">
    <thead :class="`thead-indicators ind_color_${scopeId}`">
      <tr>
        <td class="td-id"></td>
        <td class="td-desig">Indicador</td>
        <td class="td-weight">Peso</td>
        <td class="td-metric">Métrica</td>
        <td class="td-unit">Unid.</td>
        <td class="td-goal">Objetivo</td>
        <td class="td-mark">Valor crítico</td>
        <td class="td-above100">> 100%</td>
      </tr>
    </thead>

    <tbody
      :class="[row_count++ % 2 == 0 ? 'greyRow' : 'special-app-color-row']"
      :key="indicator.ind_id"
      v-for="(indicator, row_count) in arrIndicators"
    >
      <TemplateIndicatorBlock
        :objIndicator="indicator"
        :company="company"
        :class="company ? 'tbl-else' : ''"
      />
    </tbody>
  </table>
</template>

<script>
import TemplateIndicatorBlock from "./TemplateIndicatorBlock";

export default {
  name: "TemplateIndicatorList",
  props: {
    arrIndicators: Array,
    company: Boolean,
    scopeId: [String, Number],
    templateId: [String, Number],
  },
  components: {
    TemplateIndicatorBlock,
  },
};
</script>

<style scoped>

.td-weight {
  width: 12%;
}

.td-unit {
  width: 4%;
}

.td-goal {
  width: 10%;
}

.td-mark {
  width: 10%;
}

.td-above100 {
  width: 5%;
  color: var(--light-gray-color);
}

.tbl-else {
  opacity: 0.9;
  pointer-events: none;
}
</style>
