import { KPI } from "../../kpi";
import { Gems } from "../../gems";
import router from "../../router";

const state = {
  session: [],
};

const getters = {
  Session: (state) => state.session,
};

const actions = {
  async HasAccess({ dispatch }, credentials) {
    await KPI.CallBackEnd("post", "login", {
      user: credentials["username"],
      pass: credentials["password"],
    })
      .then((response) => {
        if (typeof response.data != "string") {
          const item = {
            value:
              response.data["error"] == false ? response.data["error"] : false,
          };
          const admin = {
            value: response.data["admin"],
          };
          localStorage.setItem("auth", JSON.stringify(item));
          localStorage.setItem("admin", JSON.stringify(admin));
          if (response.data["error"] == false) {
            dispatch("VerifyCycle");
          } else {
            Gems.Telegram(response.data["message"], "tele-not-allowed");
          }
          sessionStorage.setItem("logged_user_id", response.data.user_id);
        } else {
          Gems.Telegram(
            "Erro ao fazer login",
            "tele-not-allowed"
          );
        }
      })
  },

  async RecoverPasswd(_, credentials) {
    return await KPI.CallBackEnd("post", "reset-passwd", {
      user: credentials["username"],
      pass: credentials["password"],
      forgot: credentials["forgot"],
    });
  },

  async VerifyCycle({ dispatch }) {
    await KPI.CallBackEnd("get", "get-operations", {
      params: { get: 'verify-cycle', cycle: localStorage.getItem("cycle") ?? false },
    }).then((res) => {
      if (res.status == 200) {
        if (res.data?.cycle?.length <= 0) {
          localStorage.setItem("cycle", null);
        } else {
          dispatch("GetPolicy");
        }
      } else {
        localStorage.setItem("cycle", null);
      }
      router.push({ name: "cycles" });
    });
  },

  async GetPolicy() {
    const cycle = localStorage.getItem("cycle");

    await KPI.CallBackEnd("get", "policy-control").then((res) => {
      if (res.status == 200) {
        if (res.data.access == true) {
          if (cycle !== null) {
            router.push({ name: "matrix" });
          } else {
            router.push({ name: "cycles" });
          }
        } else {
          router.push({ name: "privacy" });
        }
      } else {
        Gems.Telegram(res.data, "tele-not-allowed", "");
      }
    });
  },
};

const mutations = {
  storeSession: (state, session) => {
    state.session = session;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
