<script setup>
import Master from "@/views/Master"
import { KPI } from "@/kpi"
import { onMounted, onUpdated } from "vue"
import router from "@/router"

function CleanLayout() {
  return router.currentRoute.value.meta.cleanLayout === true ? true : false
}

function CleanOrLogin() {
  return router.currentRoute.value.meta.loginLayout === true ? "" : "clean"
}

onMounted(() => {
  KPI.CheckCredentials()
})

onUpdated(() => {
  document.body.style.scrollbarGutter = ""
    if (router.currentRoute.value.meta.cleanLayout != true) 
      document.body.style.scrollbarGutter = "stable both-edges"
})

</script>


<template>
  <div :class="{'app-container': true, 'app-container-clean': CleanLayout()}">
    <div v-if="CleanLayout()" :class="CleanOrLogin()">
      <router-view />
    </div>
    <div v-else class="not-clean">
      <Master />
    </div>
  </div>
</template>

<style scoped>
.not-clean {
  width: 1330px;
  margin: 0 auto;
  background-color: white;
}

.clean {
  margin: 0 auto;
}

.app-container-clean {
  background-color: var(--medium-gray-color);
}

.app-container {
  margin-right: calc(-1 * (100vw - 100%));
}

@media only screen and (max-width: 1400px) {
  .not-clean {
    width: 1100px;
  }
}
</style>
