<template>
    <div class="div-input">
        <input type="text" ref="label" class="inputfile default-app-color cursor-pointer" @click="OpenFileSelect()"
        :placeholder="
            inputData === null
            ? 'Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]'
            : inputData.name
        "
        readonly />
        <input type="file" class="hidden-input" :id="tableName"  @change="SelectFile" />
    </div>
    <button :ref="'btnImport'" class="btn-import" @click="Import">IMPORTAR <i class="fas fa-arrow-up"></i></button>
</template>

<script>
import { Gems } from '../gems';

export default {
    name: 'ImportUploadFile',
    emits: ['SelectFile', 'import'],
    props: {
      tableName: String,
      inputData: Object
    },
    methods: {
      OpenFileSelect() {
        document.getElementById(this.tableName).click()
      },
      
      SelectFile() {
        this.$refs.btnImport.classList.remove('blink-import')
        this.$refs.btnImport.classList.add('blink-import')
        this.$emit('SelectFile', this.tableName)
      },

      Import() {
        this.$refs.btnImport.classList.remove('blink-import')
        this.$emit('import', this.tableName)
      }
    },
    
    mounted() {
      Gems.Tooltip(this.$refs['label'], "Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]", "tooltip");
    },
}
</script>

<style scoped>

.div-input {
  width: 79.8%;
}

.inputfile {
  width: 100%;
  text-align: center;
  background-color: white;
  height: 35px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.inputfile::placeholder {
  color: var(--default-app-color);
}

.hidden-input {
  display: none;
}

.btn-import {
  padding: 0px;
  height: 35px;
  width: 20%;
}

</style>
