<template>
  <component
    ref="item"
    @mouseenter="Gems.Tooltip($refs.item, tooltip, 'tooltip')"
    :is="is"
    class="interval"
    @click.stop="$emit('percentage')"
    v-if="interval == true"
  >
    <slot />
  </component>

  <component :is="is" class="item" @click.stop="Dropdown(event)" v-else>
    <slot />
  </component>
</template>

<script>
import { Gems } from '@/gems';

export default {
  name: "DropdownItem",
  emits: ['update'],
  props: {
    click: Function,
    href: {
      type: String,
      default: undefined,
    },
    interval: Boolean,
    tooltip: String,
  },
  data() {
    return {
        Gems,
    }
  },
  methods: {
    Dropdown() {
      this.$emit("update");
    },
  },
  computed: {
    is() {
      if (typeof this.href !== "undefined") {
        return "a";
      }

      return "button";
    },
  },
};
</script>

<style>
.item {
  font-size: 1em;
  height: 20px;
  background-color: transparent;
  border: none;
  width: 150px;
}

.interval {
  min-height: 25px;
  height: auto;
  flex-grow: 1;
  border-radius: 0px;
}

.interval:hover,
.item:hover  {
  cursor: pointer;
  cursor: pointer;
}

.item:hover {
  font-weight: bold;
  border-color: gray;
  color: gray;
}
</style>
