import Vuex from "vuex"
import authentication from "./modules/authentication"
import template from "./modules/template"
import matrix from "./modules/matrix"
import cycles from "./modules/cycles"
import users from "./modules/users"
import team from "./modules/team"
import entity from "./modules/entity"
// import { defineStore } from "pinia"

// Create Store

const store = new Vuex.Store({
  modules: {
    authentication,
    template,
    matrix,
    cycles,
    users,
    team,
    entity,
  },
});

export default store;
