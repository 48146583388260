<template>
  <tr>
    <td class="td-id">{{ objIndicator.ind_internal }}</td>
    <td class="td-desig" ref="designation">
      <span class="line-clamp">{{ objIndicator.ind_desig }}</span>
    </td>
    <td class="td-weight">
      <input type="text" class="txt" ref="txtWeight" maxlength="4" :value="objIndicator.ind_weight" @change="Update('txtWeight')" />
    </td>
    <td class="td-metric" ref="metric" @mouseenter="Gems.TooltipIfNeeded($refs.metric)">
      <span class="line-clamp">{{ objIndicator.ind_met }}</span>
    </td>
    <td class="td-unit">{{ objIndicator.ind_unit }}</td>
    <td class="td-goal">
      <select class="ddl-metrics" v-if="objIndicator.ind_met_id == 7">
        <option>--</option>
        <option v-for="(interval, index) in objIndicator.METRICS" :key="index" :interval="true" :title="interval.tooltip" disabled>
          {{ interval.option_value }}
        </option>
      </select>

      <input type="text" v-else ref="txtGoal" class="txt" :value="objIndicator.ind_goal" @change="Update('txtGoal')" />
    </td>
    <td class="td-unit" v-if="objIndicator.ind_met_id < 5">
      <input type="text" ref="txtMark" class="txt" :value="objIndicator.ind_mark" @change="Update('txtMark')" />
    </td>
    <td class="td-unit" v-else></td>

    <td class="td-above100">
      <select class="ddl-above100" :class="{'cursor-pointer': objIndicator.ind_met_id != 5 && objIndicator.ind_met_id != 6}" ref="above100" @change="Update('above100')" :disabled="company === true" v-if="objIndicator.ind_met_id != 5 && objIndicator.ind_met_id != 6">
        <option :selected="objIndicator.above100 == KPI.ABOVE100.NONE" :value="KPI.ABOVE100.NONE">N/A</option>
        <option :selected="objIndicator.above100 == KPI.ABOVE100.FA" :value="KPI.ABOVE100.FA">FA</option>
        <option :selected="objIndicator.above100 == KPI.ABOVE100.FO" :value="KPI.ABOVE100.FO">FO</option>
      </select>
    </td>

  </tr>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "TemplateIndicatorBlock",
  props: {
    objIndicator: {},
    company: Boolean,
    templateId: [String, Number],
  },
  data() {
    return {
      isOpen: false,
      Gems,
      KPI
    };
  },
  methods: {
    ...mapMutations(["updateIndicator"]),

    ...mapActions(['ValidateIndicator']),

    async Update(refName) {
      const auxIndicator = []
      
      Object.assign(auxIndicator, {
        ind_weight: this.$refs?.txtWeight?.value,
        ind_goal: auxIndicator.ind_met_id == 7 ? null : this.$refs?.txtGoal?.value,
        ind_mark: auxIndicator.ind_met_id >= 5 ? null : this.$refs?.txtMark?.value,
        ind_met_id: this.objIndicator.ind_met_id,
        above100: this.$refs?.above100?.value
      })

      const res = await this.ValidateIndicator(auxIndicator)
      if (!res) {
        this.$refs[refName].value = ""
        return
      }

      this.updateIndicator({indicator: this.objIndicator, new_indicator: auxIndicator})
    },
  },
  async mounted() {
    if (this.objIndicator.ind_met_id == 7) {
      const response = await KPI.CallBackEnd("get", "get-intervals", {
        params: { indicator_id: this.objIndicator.ind_id },
      })
      
      Object.assign(this.objIndicator, {
        METRICS: response.data != null ? response.data : [],
      })
    }

    if (this.$refs.designation) 
      Gems.Tooltip(this.$refs.designation, this.objIndicator.ind_desc, "tooltip" )
  },
};
</script>

<style scoped>

.td-desig,
.td-metric {
  pointer-events: all !important;
}

.txt {
  width: 50%;
  text-align: center;
}

.ddl-metrics {
  width: 62%;
  padding: 0px 5px;
}

</style>
