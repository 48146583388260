<template>
  <div class="content" v-if="draggable === true">
      <TemplatesPreviewBlock
        :key="template.temp_id"
        v-for="template in arrTemplates"
        @dragstart="StartDrag($event, template)"
        :objTemplate="template" :draggable="true" />
  </div>
  <div v-else>
    <div
      class="divScopes"
      :key="template.temp_id"
      v-for="template in arrTemplates"
    >
      <router-link
        :to="{ name: 'template', state: { temp_id: template.temp_id } }"
      >
        <TemplatesPreviewBlock
          :objTemplate="template"
          @clicked="SendToParent"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import TemplatesPreviewBlock from "../components/TemplatesPreviewBlock";

export default {
  name: "TemplatesPreviewList",
  props: {
    draggable: Boolean,
    arrTemplates: Array,
  },
  components: {
    TemplatesPreviewBlock,
  },
  methods: {
    SendToParent(value) {
      this.$emit("clicked", value);
    },

    StartDrag: (event, template) => {
      event.dataTransfer.setData("template", JSON.stringify(template));
    },
  },
};
</script>

<style scoped>
.divScopes {
  display: inline-block;
  margin: 10px 10px 0px 0px;
}

.opened-templates {
  display: inline-block;
  border-radius: 4px;
  margin: 5px auto;
}

.opened-templates:hover {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  transform: scale(1.03);
  position: relative;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 22px 10px 10px 10px;
}

@media only screen and (max-width: 1400px) {
  .opened-templates {
    width: 130px;
    margin: 5px;
  }

  .card {
    /* width: 144px; */
    width: 129px;
  }
}
</style>
