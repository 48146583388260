import { KPI } from "../../kpi";
import { Gems } from "../../gems";

const getDefaultState = () => {
  return {
    entity: [],
    comp: [],
    _360: [],
  };
};

const state = getDefaultState();

const getters = {
  arrCycleIndicators: (state) => {
    return state.entity;
  },
  CompCycles: (state) => {
    return state.comp;
  },
  _360Cycles: (state) => {
    return state._360;
  },

  CompCycleSelected: (state) => {
    return state.comp?.INDICATORS?.filter(e => e.selected)[0]
  },

  _360CycleSelected: (state) => {
    return state._360?.INDICATORS?.filter(e => e.selected)[0]
  }
};

const actions = {
  async FetchCycleIndicator({ commit }, cycle) {
    try {
      let cycleIndicatorsArr = [];
      await KPI.CallBackEnd("get", "get-cycle-indicators", {
        params: { cycle: cycle },
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.length == 0) {
            cycleIndicatorsArr.push({
              INDICATORS: [],
            });
          } else {
            cycleIndicatorsArr.push({ INDICATORS: res.data });
          }
          commit("storeIndicators", cycleIndicatorsArr);
        }
      });
    } catch (erro) {
      Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed");
    }
  },

  async GetThirdPartyApplicationCycles({ commit }, { application, appCycleChoosed }) {
    const res = await Gems.CallWS({
      method: "post",
      app: application,
      action: "get-cycles",
      body: {
        entity: JSON.parse(localStorage.getItem("entity"))["value"],
      },
    });

    if (res?.status == 200) {
      let template = { 'INDICATORS': res.data }

      template["INDICATORS"].forEach(ind => {
        ind.selected = false

        if (ind.cycle_id == appCycleChoosed && application == '360')
          ind.selected = true

        if (ind.cycle_id == appCycleChoosed && application == 'comp')
          ind.selected = true
      })

      commit(`store${application}`, template);
    }
  },

  async GetThirdPartyApplicationChoice({ dispatch }) {

    const response = await KPI.CallBackEnd("get", "get-operations", {
      params: { get: "third-party-applications-choice" },
    })

    dispatch('GetThirdPartyApplicationCycles', { application: 'comp', appCycleChoosed: response.data?.[0]?.["comp_id"] })
    dispatch('GetThirdPartyApplicationCycles', { application: '360', appCycleChoosed: response.data?.[0]?.["360_id"] })
  },

  ValidateEntityWeights({ state }) {
    let indicatorSum = 0

    state.entity[0].INDICATORS.forEach(indicator => indicatorSum += parseFloat(indicator.ind_weight))

    if (indicatorSum != 100 && state.entity[0].INDICATORS.length > 0) {
      Gems.Telegram(`A soma dos pesos dos indicadores precisa ser igual a 100`, "tele-not-allowed")
      return false
    }

    return true
  },

  async SaveTemplateCycle({ dispatch }, cycle) {
    let apps_cycles_selected;
    await dispatch("GetSelectedCycle").then((res) => {
      apps_cycles_selected = res;
    })

    if (!await dispatch('ValidateEntityWeights'))
      return false

    const response = await KPI.CallBackEnd("post", "update-cycle-indicators",
      { arrTemplate: state.entity, cycle: cycle, apps_cycles_selected: apps_cycles_selected }
    )

    if (response.error) {
      Gems.Telegram("Ocorreu um erro", "tele-not-allowed")
      return false
    }

    Gems.Telegram("Operação realizada com sucesso", "tele-success")
    dispatch("FetchCycleIndicator", cycle)
  },

  GetSelectedCycle() {
    let _360 = state._360.INDICATORS;
    let comp = state.comp.INDICATORS;
    let finalArr = { 360: null, comp: null };
    for (let cycle in _360) {
      if (_360[cycle]["selected"] == true) {
        finalArr["360"] = _360[cycle]["cycle_id"];
      }
    }
    for (let cycle in comp) {
      if (comp[cycle]["selected"] == true) {
        finalArr["comp"] = comp[cycle]["cycle_id"];
      }
    }

    return finalArr;
  },
};

const mutations = {
  storeIndicators: (state, entity) => state.entity = entity,

  storecomp: (state, comp) => state.comp = comp,

  store360: (state, _360) => state._360 = _360,

  storeMetrics: (state, metrics) => state.metrics = metrics,

  resetStateEntity: (state) => Object.assign(state, getDefaultState()),

  updateIndicatorCycle: (_, params) => Object.assign(params.indicator, params.new_indicator),

  addIndicatorCycle: (state, indicator) => state.entity[0]["INDICATORS"].push(indicator),

  removeIndicatorCycle: (state, indicator) => {
    const indicator_ind = state.entity[0].INDICATORS.findIndex(ind => ind.ind_id == indicator.ind_id)

    state.entity[0].INDICATORS.splice(indicator_ind, 1)
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
