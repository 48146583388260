import { KPI } from "../kpi"
import store from "../store";

export const useTableMetric = async (objIndicator) => {
    if (objIndicator.ind_met_id == 7) {
        const response = await KPI.CallBackEnd("get", "get-intervals", {
            params: { indicator_id: objIndicator.ind_id },
        })

        Object.assign(objIndicator, {
            METRICS: response.data != null ? response.data : [],
        });

        if (objIndicator.direct_matching == 1)
            objIndicator.METRICS.forEach((elem) => {
                if (elem.evaluation == objIndicator.ind_vol)
                    store.commit('calculateDirectMatchingEvaluation', {
                        indicator: objIndicator,
                        optionSelected: elem,
                    });
            });
        else
            objIndicator.METRICS.forEach((elem) => {
                objIndicator.tooltip = 'intervalo inexistente'
                if (objIndicator.ind_vol >= parseInt(elem.option_value, 10) && objIndicator.ind_vol < parseInt(elem.interval_max, 10))
                    objIndicator.tooltip = elem.tooltip
            });
    }

}
