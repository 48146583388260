<template>
  <div>
    <table class="tbl tbl-without-scroll">
      <tr class="tbl-header">
        <td class="tdInternal"></td>
        <td class="tdDesignation"> Indicador </td>
        <td class="tdMetric"> Métrica </td>
        <td class="td-unit"> Unidade </td>
        <td class="td-include"> Incluir </td>
      </tr>
    </table>
    <div class="divScroll scrollbar">
      <table class="tbl" v-if="cycle == true">
        <tbody
          :key="indicator.ind_id"
          v-for="(indicator, row_count) in arrIndicators"
          :class="[row_count++ % 2 == 0 ? 'alt-color' : '']"
        >
          <SearchIndicatorBlock :objIndicator="indicator" :cycle="cycle" />
        </tbody>
      </table>
      <table class="tbl" v-else-if="team == true">
        <tbody
          :key="indicator.ind_id"
          v-for="(indicator, row_count) in arrIndicators"
          :class="[row_count++ % 2 == 0 ? 'alt-color' : '']"
        >
          <SearchIndicatorBlock :objIndicator="indicator" :team="true" />
        </tbody>
      </table>
      <table class="tbl" v-else>
        <tbody
          :key="indicator.ind_id"
          v-for="(indicator, row_count) in arrIndicators"
          :class="[row_count++ % 2 == 0 ? 'alt-color' : '']"
        >
          <SearchIndicatorBlock :objIndicator="indicator" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SearchIndicatorBlock from "./SearchIndicatorBlock";

export default {
  name: "SearchIndicatorList",
  props: {
    arrIndicators: Array,
    cycle: Boolean,
    team: Boolean,
  },
  components: {
    SearchIndicatorBlock,
  },
};
</script>

<style scoped>
.tbl {
  width: 100%;
  border-spacing: 2px;
  margin-top: 5px;
}

.tbl-header {
  font-weight: 500;
  text-align: center;
  font-size: 0.8rem;
}

::v-deep(.tdInternal) {
  width: 5%;
  text-align: center;
}

::v-deep(.tdDesignation) {
  width: 40%;
}

::v-deep(.tdMetric) {
  width: 20%;
}

::v-deep(.td-unit) {
  width: 5%;
  text-align: center;
}

::v-deep(.td-include) {
  width: 5%;
  text-align: center;
}

.tdScroll {
  width: 14px;
}
.divScroll {
  min-height: 150px;
  max-height: 150px;
  overflow-y: scroll;
}

.greyRow {
  background-color: rgb(233, 231, 231);
}

.alt-color {
  background-color: var(--special-app-color);
}

.tbl-without-scroll {
  padding-right: 5px;
}
</style>
