<template>
  <tr>
    <td class="tdID"></td>
    <td class="tdDesig">
      <p class="line-clamp" ref="cycleName" @mouseenter="Gems.TooltipIfNeeded($refs.cycleName)">
        {{ objIndicator.cycle }}
      </p>
    </td>
    <td class="td-date">{{ objIndicator.date_i }}</td>
    <td class="td-date">{{ objIndicator.date_f }}</td>
    <td class="td-active">
      <i class="far fa-check-circle" aria-hidden="true" v-if="objIndicator.cycle_active == 1"></i>
    </td>
    <td class="td-select" v-if="template != true">
      <input
        :class="select != true ? 'disabled' : 'cursor-pointer'"
        type="radio"
        :id="objIndicator.cycle_id"
        :value="objIndicator.cycle_id"
        :checked="picked == objIndicator.cycle_id"
        @click="$emit('update', objIndicator.cycle_id)"
      />
    </td>
  </tr>
</template>

<script>
import { Gems } from "../gems";

export default {
  name: "TemplateCirvalueBlock",
  props: {
    objIndicator: {},
    picked: String,
    select: Boolean,
    template: Boolean,
  },
  data() {
    return {
      Gems,
    };
  },
};
</script>

<style scoped>

.td-date,
.td-active,
.td-select {
  text-align: center;
}

</style>
